import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Localization from '../../../utils/localization';

const ActivityFailure = props => {
  const history = useHistory();
  const { data, resetPrevAnswer, arabic } = props;
  const { title, content } = data;

  useEffect(() => {
    resetPrevAnswer();
  }, []);

  const retryHandler = () => {
    history.push(`/${data.retry_page_url.path}`);
  };

  return (
    <div className="content-area">
      <Title className="title-failed bg-dark">
        <div className="user-image-title flex-column">
          <img src={title} alt="" />
        </div>
      </Title>

      <div>
        <div className="content">
          <div className="fs-23 fw-600 text-white">
            {content && <HtmlParser className={arabic}>{content}</HtmlParser>}
          </div>
        </div>

        <div className="footer failed-footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={retryHandler}
              type="button"
              className="btn btn-primary radius-0 text-right next-btn"
            >
              <img src="/img/icons/retry.svg" alt="" className="mr-3" />
              <Localization text="retry" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityFailure;
