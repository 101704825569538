import {
  IS_FAILURE,
  FINAL_ASSESSMENT_INTRO,
  CHANGE_LANGUAGE,
  AUTH_TOKEN,
  NO_HEADER_PAGE,
  AUTH_REFRESH_TOKEN,
  REDIRECT_PAGE_URL,
  LANDSCAPE_MODE_ON,
  AUTH_SESSION,
  EXPIRE_TIME
} from '../type';

const initialState = {
  failurPage: false,
  finalAssessmentIntroPage: false,
  authSession: false,
  language: '',
  token: '',
  refreshToken: '',
  redirectUrl: '',
  landscapePage: false,
  noHeader: false,
  expireTime: ''
};

const initReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_FAILURE:
      return {
        ...state,
        failurPage: action.status,
      };
    case FINAL_ASSESSMENT_INTRO:
      return {
        ...state,
        finalAssessmentIntroPage: action.status,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case AUTH_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case AUTH_SESSION:
      return {
        ...state,
        authSession: action.status,
      };
    case AUTH_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.token,
      };
    case NO_HEADER_PAGE:
      return {
        ...state,
        noHeader: action.status,
      };
    case REDIRECT_PAGE_URL:
      return {
        ...state,
        redirectUrl: action.url,
      };
    case LANDSCAPE_MODE_ON:
      return {
        ...state,
        landscapePage: action.status,
      };
    case EXPIRE_TIME:
      return {
        ...state,
        expireTime: action.time,
      };
    default:
      return state;
  }
};

export default initReducer;
