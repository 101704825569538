export const IS_FAILURE = 'IS_FAILURE';
export const FINAL_ASSESSMENT_INTRO = 'FINAL_ASSESSMENT_INTRO';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_SESSION = 'AUTH_SESSION';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const NO_HEADER_PAGE = 'NO_HEADER_PAGE';
export const REDIRECT_PAGE_URL = 'REDIRECT_PAGE_URL';
export const LANDSCAPE_MODE_ON = 'LANDSCAPE_MODE_ON';
export const MODULES = 'MODULES';
export const MODAL_CONTENT = 'MODAL_CONTENT';
export const EXPIRE_TIME = 'EXPIRE_TIME';
