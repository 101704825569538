import { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import { BackIcon, NextIcon } from '../../components/ui/icons';
import Title from '../../layout/Title';
import HtmlParser from '../../utils/htmlParser';
import Localization from '../../utils/localization';
import refreshTokenHandler from '../../utils/generateToken';
import useFetchAuthData from '../../utils/useFetchAuthData';

const Overview = props => {
  const dispatch = useDispatch();
  const pathName = props.history.location.pathname;
  const [activeTab, setActiveTab] = useState('1');
  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;
  const { data, isLoading, fetch } = useFetchAuthData(pathName);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onPrevHandler = () => {
    props.history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          props.history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken);
          }
        });
    } else {
      props.history.push(`/${data.next_page_url.path}`);
    }
  };

  useEffect(() => {
    fetch();
  }, [pathName]);

  return isLoading ? (
    <div
      className="d-flex align-items-center justify-content-center p-5 h-100 mx-100"
      style={{ width: '435px' }}
    >
      <Spinner size="lg" color="warning" />
    </div>
  ) : (
    <div className="content-area">
      <Title className="bg-dark text-primary">
        <h1
          className={`fs-20 fw-900 ${language === 'ar' ? 'arabic-text' : ''}`}
        >
          {data && data.title}
        </h1>
        <h3
          className={`fs-17 fw-600 lh-21 mt-2 ${
            language === 'ar' ? 'arabic-text' : ''
          }`}
        >
          {data && data.is_final_assessment ? (
            <Localization text="finalAssessment" />
          ) : (
            <>
              <Localization text="module" /> {data && data.position}{' '}
              <Localization text="of" /> {data && data.total_module} <br />
              <Localization text="duration" />: {data && data.duration / 60}{' '}
              <Localization text="hours" />
            </>
          )}
        </h3>
      </Title>

      <div className="justify-content-start">
        <div className={`content ${language === 'ar' ? 'arabic-text' : ''}`}>
          <div className="overview">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  <Localization text="overview" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  <Localization text="contents" />
                </NavLink>
              </NavItem>

              {data &&
                data.resource !== null &&
                data.resource !== '' &&
                data.resource !== '<p><br></p>' && (
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        toggle('3');
                      }}
                    >
                      <Localization text="resources" />
                    </NavLink>
                  </NavItem>
                )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <HtmlParser className="overview-block">
                  {data && data.overview}
                </HtmlParser>
              </TabPane>
              <TabPane tabId="2">
                {data &&
                  data.sections.map((section, i) => {
                    return (
                      <div className="section-overview mb-4" key={i}>
                        <h3 className="fs-17 fw-700 text-primary">
                          <Localization text="section" /> {i + 1}
                        </h3>
                        <Link
                          to={
                            data &&
                            `/${
                              section.is_unlocked
                                ? section.section_url.path
                                : '#'
                            }`
                          }
                          className={`fs-20 text-body fw-700 border-bottom ${
                            data &&
                            (section.is_unlocked ? '' : 'disabled-content')
                          }`}
                        >
                          {section.title}
                        </Link>
                        <ul className="fw-700 text-secondary pl-5 list-unstyled">
                          {section.pages.map((page, i) => {
                            return (
                              <li
                                className={`border-bottom mt-2 ${
                                  language === 'ar' ? 'arabic-text' : ''
                                }`}
                                key={i}
                              >
                                <Link
                                  to={data && `/${page.page_url.path}`}
                                  className={`text-secondary ${
                                    data &&
                                    (page.is_page_unlocked
                                      ? ''
                                      : 'disabled-content')
                                  }`}
                                >
                                  {page.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
              </TabPane>
              <TabPane tabId="3">
                <HtmlParser className="overview-block">
                  {data && data.resource}
                </HtmlParser>
              </TabPane>
            </TabContent>
          </div>
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
            >
              <Localization text="start" />
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Overview);
