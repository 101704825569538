import { useEffect } from 'react';

import ContentArea from '../../hoc/ContentArea';
import Title from '../../layout/Title';
import useFetchAuthData from '../../utils/useFetchAuthData';
import Localization from '../../utils/localization';
const Welcome = () => {
  const { data, isLoading, fetch } = useFetchAuthData('/dashboard');

  useEffect(() => {
    fetch();
  }, []);

  return (
    <ContentArea
      title={
        <Title className="bg-special">
          <div className="user-image-title">
            <img src="/img/user.png" alt="" style={{ height: 'initial' }} />
          </div>
        </Title>
      }
      buttonToGo="modules"
      btnColor="special"
      iconColor="white"
    >
      <h4 className="text-primary fw-900 text-center">
        <Localization text="successSignInMsg" /> {data && data.name}
      </h4>
    </ContentArea>
  );
};

export default Welcome;
