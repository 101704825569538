import _ from 'lodash';

export const getCorrectAnswerSet = questions => {
  return questions.length > 1
    ? questions.map(question => {
        if (question.type === 1) {
          // for checkbox type Question
          return question.correct_answers;
        } else if (question.type === 2) {
          // for radio type Question
          return question.correct_answers[0];
        }
      })
    : questions[0].type === 1
    ? questions[0].correct_answers
    : questions[0].correct_answers[0];
};

export const isCorrect = (questions, submittedAnswer) => {
  return _.isEqual(submittedAnswer, getCorrectAnswerSet(questions));
};

export const getAnswerSet = (questions, submittedAnswer) => {
  if (questions.length > 1) {
    return questions.map((question, i) => {
      return {
        question_id: question.id,
        is_correct: question.correct_answers[0] === submittedAnswer[i] ? 1 : 0,
      };
    });
  }
  return [
    {
      question_id: questions[0].id,
      is_correct: _.isEqual(submittedAnswer, getCorrectAnswerSet(questions))
        ? 1
        : 0,
    },
  ];
};

export const questionGroupByPageId = (questions = []) => {
  const wrongQuestion = questions.filter(q => q.is_correct === 0);
  const questionsSet = [];

  questions.forEach((q, i) => {
    const index = questionsSet.findIndex(ques => ques.page_id === q.page_id);

    if (index === -1) {
      questionsSet.push({
        index: q.question.question_index,
        page_id: q.page_id,
        is_correct: q.is_correct,
        correct_answers: q.correct_answers,
        options: q.options,
        questions: [q.question],
      });
    } else {
      questionsSet[index].questions.push(q.question);
      questionsSet[index].correct_answers.push(q.correct_answers[0]);
    }
  });

  return questionsSet.sort((a, b) => (a.index > b.index ? 1 : -1));
};

export const wrongQuestionByPageId = questions => {
  const wrongQuestion = questions.filter(q => q.is_correct === 0);

  const questionsSet = [];

  wrongQuestion.forEach(q => {
    const index = questionsSet.findIndex(ques => ques.page_id === q.page_id);

    if (index === -1) {
      questionsSet.push({
        index: q.question.question_index,
        page_id: q.page_id,
        is_correct: q.is_correct,
        options: q.options,
        questions: [
          {
            ...q.question,
            correct_answers: q.correct_answers.map(
              key => `${key}: ${q.options[key]}`
            ),
          },
        ],
      });
    } else {
      questionsSet[index].questions.push({
        ...q.question,
        correct_answers: q.correct_answers.map(
          key => `${key}: ${q.options[key]}`
        ),
      });
    }
  });

  return questionsSet;
};
