import { MODULES, MODAL_CONTENT } from '../type';

const initialState = {
  modules: null,
  modalContent: null,
  isFetching: false,
};

const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODULES:
      return {
        ...state,
        modules: action.data,
      };
    case MODAL_CONTENT:
      return {
        ...state,
        modalContent: action.data,
      };
    case 'FETCHING_MODULES':
      return {
        ...state,
        isFetching: action.payload,
      };
    default:
      return state;
  }
};

export default modulesReducer;
