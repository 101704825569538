import React from 'react';
// var palyIcon = process.env.PUBLIC_URL + `/img/shape.svg`;
// var palyIcon = `/img/shape.svg`;

// export const PlayIcon = props => {
//   return <img alt={props.alt} className={props.className} src={palyIcon} />;
// };

export const BarIcon = ({ color, ...rest }) => (
  <svg
    data-name="Component 23 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="19"
    viewBox="0 0 22 19"
    {...rest}
  >
    <rect
      data-name="Rectangle 1607"
      width="22"
      height="3"
      fill={color ? color : 'currentColor'}
    />
    <rect
      data-name="Rectangle 1608"
      width="22"
      height="3"
      transform="translate(0 8)"
      fill={color ? color : 'currentColor'}
    />
    <rect
      data-name="Rectangle 1609"
      width="22"
      height="3"
      transform="translate(0 16)"
      fill={color ? color : 'currentColor'}
    />
  </svg>
);

export const BackIcon = ({ color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.288"
    height="14.975"
    viewBox="0 0 44.288 14.975"
    {...rest}
  >
    <g data-name="Group 789" transform="translate(44.288 14.975) rotate(180)">
      <path
        data-name="Path 743"
        d="M1107.29,1043.239A30.283,30.283,0,0,1,1111,1037.1H1073.69v-2.689H1111a30.167,30.167,0,0,1-3.712-6.143h2.24a28.2,28.2,0,0,0,8.448,6.912v1.152a28.221,28.221,0,0,0-8.448,6.911Z"
        transform="translate(-1073.69 -1028.264)"
        fill={color ? color : 'currentColor'}
      />
    </g>
  </svg>
);

export const NextIcon = ({ color, ...rest }) => (
  <svg
    data-name="Group 788"
    xmlns="http://www.w3.org/2000/svg"
    width="44.288"
    height="14.975"
    viewBox="0 0 44.288 14.975"
    {...rest}
  >
    <path
      data-name="Path 743"
      d="M1107.29,1043.239A30.283,30.283,0,0,1,1111,1037.1H1073.69v-2.689H1111a30.167,30.167,0,0,1-3.712-6.143h2.24a28.2,28.2,0,0,0,8.448,6.912v1.152a28.221,28.221,0,0,0-8.448,6.911Z"
      transform="translate(-1073.69 -1028.264)"
      fill={color ? color : 'currentColor'}
    />
  </svg>
);

export const DownIcon = ({ color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.975"
    height="44.288"
    viewBox="0 0 14.975 44.288"
    {...rest}
  >
    <g data-name="Arrow right" transform="translate(0 44.288) rotate(-90)">
      <g data-name="Group 787" transform="translate(44.288 14.975) rotate(180)">
        <path
          data-name="Path 743"
          d="M1107.29,1043.239A30.283,30.283,0,0,1,1111,1037.1H1073.69v-2.689H1111a30.167,30.167,0,0,1-3.712-6.143h2.24a28.2,28.2,0,0,0,8.448,6.912v1.152a28.221,28.221,0,0,0-8.448,6.911Z"
          transform="translate(-1073.69 -1028.264)"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </g>
  </svg>
);

export const ZoomIcon = ({ color, ...rest }) => <svg xmlns="http://www.w3.org/2000/svg" width="35.036" height="40.036" viewBox="0 0 35.036 40.036" {...rest}>
<g data-name="Ellipse 12" transform="translate(0 5)" fill="none" stroke={color ? color : 'currentColor'} strokeWidth="5">
  <circle cx="13" cy="13" r="13" stroke="none"/>
  <circle cx="13" cy="13" r="10.5" fill="none"/>
</g>
<line data-name="Line 31" x2="10" y2="10" transform="translate(21.5 26.5)" fill="none" stroke={color ? color : 'currentColor'} strokeLinecap="round" strokeWidth="5"/>
<text data-name="+" transform="translate(13.5 27)" fill={color ? color : 'currentColor'} fontSize="25" fontFamily="NotoSans-Black, Noto Sans" fontWeight="800"><tspan x="-7.325" y="0">+</tspan></text>
</svg>

export const ZoomoutIcon = ({ color, ...rest }) => <svg xmlns="http://www.w3.org/2000/svg" width="35.036" height="42.036" viewBox="0 0 35.036 42.036" {...rest}>
<g data-name="Ellipse 12" transform="translate(0 7)" fill="none" stroke={color ? color : 'currentColor'} strokeWidth="5">
  <circle cx="13" cy="13" r="13" stroke="none"/>
  <circle cx="13" cy="13" r="10.5" fill="none"/>
</g>
<line data-name="Line 31" x2="10" y2="10" transform="translate(21.5 28.5)" fill="none" stroke={color ? color : 'currentColor'} strokeLinecap="round" strokeWidth="5"/>
<text data-name="-" transform="translate(12.5 27)" fill={color ? color : 'currentColor'} fontSize="25" fontFamily="NotoSans-Black, Noto Sans" fontWeight="800"><tspan x="-3.975" y="0">-</tspan></text>
</svg>

export const InfoIcon = ({ color, ...rest }) => <svg xmlns="http://www.w3.org/2000/svg" width="35" height="27" viewBox="0 0 35 27" {...rest}>
<text fill={color ? color : 'currentColor'} fontSize="20" fontFamily="NotoSans-Bold, Noto Sans" fontWeight="700"><tspan x="14.45" y="21">i</tspan></text>
</svg>
