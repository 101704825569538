import { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';

import HtmlParser from '../utils/htmlParser';
import Localization from '../utils/localization';
import { changeLanguageDispatch } from '../redux/dispatchs/uiHelperDispatch';

let privacy_policy_pdf = {
  ar:
    'https://drive.google.com/file/d/1S7K9vUi1sqMTe3k1uhTmtY5xM7084--F/view?usp=sharing',
  en:
    'https://drive.google.com/file/d/1w3X5wjp6N-82ZjVUcvxUWdqYAJ_WTx-A/view?usp=sharing',
  es:
    'https://drive.google.com/file/d/1TcXYgFjyl6oogHFItjghi94u0zetZ4OZ/view?usp=sharing',
  pt:
    'https://drive.google.com/file/d/1upMlnM0wTfPI2b9GN91V9Dn2wDUKkJlm/view?usp=sharing',
};

let about_authors_pdf = {
  ar:
    'https://drive.google.com/file/d/1rmrCz47p_YUZ4FGSr_Hg6BKShRjHX9rL/view?usp=sharing',
  en:
    'https://drive.google.com/file/d/1mFnPgQU1zmIFoA1o1fZNCIUCrPC32RZO/view?usp=sharing',
  es:
    'https://drive.google.com/file/d/1FdgEpfNBXLQZDvwU9sCetOxEz0uLenPN/view?usp=sharing',
  pt:
    'https://drive.google.com/file/d/1hP2Mu18qsdia5N1RPitHsM96SW6nqJs3/view?usp=sharing',
};

const Promo = () => {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.uiHelper);
  const { modalContent } = useSelector(state => state.modules);

  const [openedModal, setOpenedModal] = useState('');

  const onCloseModal = () => {
    setOpenedModal('');
  };
  const onLangChangeHandler = e => {
    changeLanguageDispatch(dispatch, e.target.value);
  };

  const getPromoVideoLink = () => {
    switch (language) {
      case 'ar':
        return 'https://www.youtube.com/embed/NqNvMnymszg';
      case 'pt':
        return 'https://www.youtube.com/embed/5ueLEd5zyN4';
      case 'es':
        return 'https://www.youtube.com/embed/TpT9fc1U4SI';
      default:
        return 'https://www.youtube.com/embed/-qyRkgDYu8I';
    }
  };

  const getTutorialVideoLink = () => {
    switch (language) {
      case 'ar':
        return 'https://www.youtube.com/embed/GiTb_fEfNgI';
      case 'pt':
        return 'https://www.youtube.com/embed/w08M9462I4Y';
      case 'es':
        return 'https://www.youtube.com/embed/H8rbPOA9Yrs';
      default:
        return 'https://www.youtube.com/embed/GfvJALV_-ss';
    }
  };

  return (
    <>
      <div
        className={`promo-content ${
          (language === 'ar' && 'arabic-text') || ''
        }`}
      >
        <div className="">
          <div className="select-lang">
            <select
              onChange={onLangChangeHandler}
              value={language ? language : 'en'}
            >
              <option value="en">English (en)</option>
              <option value="es">Español - Internacional (es)</option>
              <option value="pt">Português - Portugal (pt)</option>
              <option value="ar">العربية (ar)</option>
            </select>
          </div>
          <h1 className="title1">
            <Localization text="promoWelcomeToOSHPorts" />
          </h1>

          <div className="video-pos">
            <iframe
              width="100%"
              height="100%"
              src={getPromoVideoLink()}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className="descr1">
            <p>
              <Localization text="promoOSHPortsDesc1" />
            </p>
            <p>
              <Localization text="promoOSHPortsDesc2" />
            </p>

            <ul>
              <li>
                <Localization text="promoOSHPortsDescLI1" />
              </li>
              <li>
                <Localization text="promoOSHPortsDescLI2" />
              </li>
              <li>
                <Localization text="promoOSHPortsDescLI4" />
              </li>
            </ul>
          </div>

          <div className="down-app">
            <h2>
              {/* Download <span className="d-m-none">the app now!</span> */}
              <Localization text="downloadOSH" />
            </h2>
            <div className="donwloadBtn">
              <a
                href="https://play.google.com/store/apps/details?id=com.portonics.osh"
                target="_blank"
              >
                {/* <img src="/img/apk-download.png" alt="" /> */}
                <img src="/img/gplay.svg" alt="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/osh-ports/id6451102353?platform=iphone"
                target="_blank"
              >
                <img src="/img/apple.svg" alt="" />
              </a>
            </div>
            <p>
              <Localization text="appsLogoLLC" />
            </p>
            <p style={{ marginTop: '0px' }}>
              <Localization text="appleAppsLogoLLC" />
            </p>
          </div>

          <div className="sign-in-osh">
            <h1>
              <Localization text="singInTitle" />
            </h1>
            <p className="mt-3">
              <Localization text="signDesc1" />
            </p>
            <p className="mt-3">
              <Localization text="signDesc2" />
            </p>

            <div className="moodle-banner mt-4.5 align-items-center d-flex justify-content-start rounded-lg overflow-hidden">
              <a
                href="http://learn.oshports.org/"
                className=" btn-special align-items-center bg-special align-self-stretch d-flex justify-content-center"
              >
                <Localization text="takeMeToTheNewApp" />
              </a>
              <img src="/img/moodle-banner.png" className="m-0" />
            </div>
          </div>

          <div className="promo-instruction">
            {/* <p className="user-attention">
              <Localization text="attensionForArab" />
            </p> */}
            {/* <div className="content">
              <Link
                to="/login"
                className="btn btn-special btn-block text-uppercase fs-14 fw-900 text-left px-4"
              >
                <Localization text="login" />
                <img src="img/icons/next.svg" alt="" className="ml-3 mr-3" />
              </Link>
              <Link
                to="/registration"
                className="btn btn-secondary btn-block text-uppercase fs-14 fw-900 text-left mt-4 px-4"
              >
                <Localization text="signup" />
                <img
                  src="img/icons/next-black.svg"
                  alt=""
                  className="ml-3 mr-3"
                />
              </Link>
            </div> */}

            <div className="w-991 video-tutorial">
              <p className="mt-4 mb-4">
                <Localization text="tutorials_osh" />
              </p>
            </div>

            <div className="video-pos">
              <iframe
                width="100%"
                height="100%"
                src={getTutorialVideoLink()}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-pos-line"></div>

            <div className="collage-section">
              <img src="/img/img-collage.png" alt="" />
            </div>

            <div className="quotes w-991">
              <h2>
                <Localization text="promoQuotes" />
              </h2>

              <p className="author">
                <Localization text="promoQuoteOwner" />
              </p>
              <p className="author_designation">
                <Localization text="promoQuoteOwnerDesignation" />
                <br /> <Localization text="promoQuoteOwnerOrganization" />
              </p>
            </div>

            <div className="partners">
              <h2>
                <Localization text="partners" />
              </h2>

              <div className="partners__logo">
                <img src="/img/Seafarers__Trust_line.png" alt="" />
                {/* <img src="/img/osh-logo-itcilo-supported.png" alt="" /> */}
                <img
                  src={`/img/ict-ilo-blue-${
                    language === 'ar' ? 'en' : language
                  }.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`promo-footer ${(language === 'ar' && 'arabic-text') || ''}`}
      >
        <ul>
          {/* <li
            className="pointer hover_underline"
            // onClick={() => setOpenedModal('tnc')}
          >
            <Localization text="usingOSHPorts" />
          </li> */}
          <li
            className="pointer hover_underline"
            // onClick={() => setOpenedModal('pp')}
          >
            <a href={privacy_policy_pdf[language || 'en']} target="_blank">
              <Localization text="privacyPolicy" />
            </a>
          </li>
          <li
            className="pointer hover_underline"
            // onClick={() => setOpenedModal('tnc')}
          >
            <a href={privacy_policy_pdf[language || 'en']} target="_blank">
              <Localization text="termsAndCodition" />
            </a>
          </li>
          <li
            className="pointer hover_underline"
            // onClick={() => setOpenedModal('about_authors')}
          >
            <a href={about_authors_pdf[language || 'en']} target="_blank">
              <Localization text="aboutTheAuthors" />
            </a>
          </li>
        </ul>

        <p>
          <Localization text="contactITF" />{' '}
        </p>
        <h3>info@seafarerstrust.org</h3>
        <p>
          <Localization text="contactITFSecondRow" />{' '}
        </p>
        <h3>trust@itf.org.uk</h3>
      </div>

      <Modal open={openedModal === 'tnc'} onClose={onCloseModal} center>
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>
            {modalContent && modalContent.terms_and_condition}
          </HtmlParser>
        </div>
      </Modal>
      <Modal open={openedModal === 'pp'} onClose={onCloseModal} center>
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>{modalContent && modalContent.privacy_policy}</HtmlParser>
        </div>
      </Modal>
      <Modal
        open={openedModal === 'about_authors'}
        onClose={onCloseModal}
        center
      >
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>{modalContent && modalContent.about_author}</HtmlParser>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(Promo);
