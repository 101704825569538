import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  authTokenDispatch,
  authRefreshTokenDispatch,
  expireTimeDispatch,
} from '../redux/dispatchs/uiHelperDispatch';

const useFetchAuthData = url => {
  const [data, setData] = useState();
  // const [isCalledRefreshToken, setIsCalledRefreshToken] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;

  const dispatch = useDispatch();

  const fetch = () => {
    if (token) {
      setIsLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}${url}`, {
          headers: {
            'Accept-Language': language ? language : 'en',
            Authorization: 'Bearer ' + token,
          },
        })
        .then(res => {
          setData(res.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          if (error.response.status === 401) {
            const date = new Date();
            console.log('refreshToken - 1')

            axios
              .post(`${process.env.REACT_APP_API_URL}/refresh`, {
                refresh_token: refreshToken,
              })
              .then(res => {
                date.setTime(
                  date.getTime() + res.data.data.expire_in_seconds * 1000
                );
                expireTimeDispatch(dispatch, date.toUTCString());
                authTokenDispatch(dispatch, res.data.data.token);

                setFetchAgain(true);
              })
              .catch(error => {
                expireTimeDispatch(dispatch, '');
                authTokenDispatch(dispatch, '');
                authRefreshTokenDispatch(dispatch, '');

                history.push('/login');
              });
          }

          setIsLoading(false);
        });
    } else if (refreshToken) {
      const date = new Date();
      console.log('refreshToken - 2')
      axios
        .post(`${process.env.REACT_APP_API_URL}/refresh`, {
          refresh_token: refreshToken,
        })
        .then(res => {
          date.setTime(date.getTime() + res.data.data.expire_in_seconds * 1000);
          expireTimeDispatch(dispatch, date.toUTCString());
          authTokenDispatch(dispatch, res.data.data.token);

          setFetchAgain(true);
        })
        .catch(error => {
          expireTimeDispatch(dispatch, '');
          authTokenDispatch(dispatch, '');
          authRefreshTokenDispatch(dispatch, '');

          history.push('/login');
        });
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    if (fetchAgain) {
      console.log('refreshToken - 5')
      fetch();
    }
  }, [token, fetchAgain]);

  useEffect(() => {
    if (data) {
      fetch();
    }
  }, [language]);

  return { data, isLoading, fetch };
};

export default useFetchAuthData;
