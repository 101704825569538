import axios from 'axios';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const ActivitySuccess = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, resetPrevAnswer, arabic } = props;
  const { title, content } = data;
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    resetPrevAnswer();
  }, []);

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
          // console.log(res.data)
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken)
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  return (
    <div
      className={`content-area ${data.is_landscape ? 'landscape-mode' : ''}`}
    >
      <Title>
        <img src={title} alt="" />
      </Title>

      <div>
        <div className="content">
          {content && <HtmlParser className={arabic}>{content}</HtmlParser>}
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-primary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
            >
              <Localization text="next" />
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySuccess;
