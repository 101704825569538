import { FormGroup, Label, Input } from 'reactstrap';
import HtmlParser from '../../utils/htmlParser';

const Textarea = props => {
  const { question, getAnswer, className } = props;

  const onChangeHandler = e => {
    getAnswer(e.target.value);
  };

  return (
    <FormGroup>
      <Label htmlFor={`text-${question.id}`}><HtmlParser className={className}>{question.title}</HtmlParser></Label>
      <Input
        type="textarea"
        onChange={onChangeHandler}
        name={`text-${question.id}`}
        id={`text-${question.id}`}
      />
    </FormGroup>
  );
};

export default Textarea;
