import { useHistory } from 'react-router-dom';

import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';

import { wrongQuestionByPageId } from '../../../utils/question';
import Localization from '../../../utils/localization';

const FinalAssessmentAlmostPass = props => {
  const history = useHistory();
  const { data, arabic } = props;
  const { title, content, questions, show_correct_answer } = data;

  const retryHandler = () => {
    history.push(`/${data.retry_page_url.path}`);
  };

  return (
    <div className="content-area">
      <Title className="title-failed bg-dark">
        <div className="user-image-title flex-column">
          <img src={title} alt="" />
        </div>
      </Title>

      <div>
        <div className="content">
          <div className="fs-23 fw-600">
            {content && <HtmlParser className="">{content}</HtmlParser>}
          </div>

          <div className={`wrong-questions mt-3 ${arabic}`}>
            {wrongQuestionByPageId(questions).map((q, i) => (
              <div key={i} className={`wrong-questions__question fs-23 mt-2`}>
                <p className="text-primary fw-900 mt-4 mb-1">
                  <Localization text="question" /> {q.index}
                </p>
                {q.questions.map(({ title, correct_answers }) => (
                  <>
                    <p className="mb-3" style={{ color: '#212529' }}>
                      <HtmlParser className="">
                        {title.replace(
                          '##',
                          '<span style="background-color: #ffd43b;width: 82px; height: 12px;display: inline-block;"></span>'
                        )}
                      </HtmlParser>
                    </p>

                    {show_correct_answer && (
                      <div
                        style={{
                          font: 'normal normal 600 16px/22px Noto Sans',
                          letterSpacing: '0px',
                          color: '#212529',
                          opacity: 1,
                          marginTop: '-12px',
                          marginBottom: '16px',
                        }}
                      >
                        {correct_answers.map((answers, i) => (
                          <p key={i} className="py-1">
                            {answers}
                          </p>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="footer failed-footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={retryHandler}
              type="button"
              className="btn btn-primary radius-0 text-right next-btn"
            >
              <img src="/img/icons/retry.svg" alt="" className="mr-3" />
              <Localization text="retry" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalAssessmentAlmostPass;
