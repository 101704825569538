import Footer, { FailedFooter } from '../layout/Footer';

const ContentArea = props => {
  const { title, children, failedFooter, startTop, ...rest } = props;

  return (
    <div className="content-area">
      {title && title}

      <div className={startTop ? 'justify-content-start' : ''}>
        <div className="content">{children}</div>
        {failedFooter ? <FailedFooter {...rest} /> : <Footer {...rest} />}
      </div>
    </div>
  );
};

export default ContentArea;
