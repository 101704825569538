import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Questions from '../../../components/questions/Questions';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const ActivityQuiz = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data,
    prevAnswersHandler,
    isCorrectAnswer,
    prevAnswers,
    isPassed,
    arabic,
  } = props;

  const { title, content, questions, title_as_image } = data;
  const [isGotoNext, setIsGotoNext] = useState(false);
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.is_activity_quiz_end_page) {
      if (isPassed()) {
        history.push(`/${data.success_page_url.path}`);
      } else {
        history.push(`/${data.failure_page_url.path}`);
      }
    } else {
      if (data.next_page_url === null) {
        axios
          .post(
            data.next_page_unlock_url,
            {},
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          )
          .then(res => {
            history.push(`/${res.data.data.next_page_url.path}`);
          })
          .catch(err => {
            if (err.response.status === 401) {
              refreshTokenHandler(dispatch, refreshToken);
            }
          });
      } else {
        history.push(`/${data.next_page_url.path}`);
      }
    }
  };

  useEffect(() => {
    if (!data.questions) {
      setIsGotoNext(true);
    }
  }, [data]);

  return (
    <div className="content-area">
      <Title className={`${title_as_image ? '' : 'bg-dark'} ${arabic}`}>
        {title_as_image ? (
          <>
            <img src={title} alt="" className="h-100" />
          </>
        ) : (
          <>
            <p className="fs-17 text-secondary fw-700">
              <Localization text="activity" />
            </p>
            <h4 className="fs-20 text-secondary-light fw-900">{title}</h4>
          </>
        )}
      </Title>

      <div>
        <div className={`content ${arabic}`}>
          {content && <HtmlParser className={arabic}>{content}</HtmlParser>}
          {questions && (
            <Questions
              questions={questions}
              setIsGotoNext={setIsGotoNext}
              prevAnswersHandler={answers => {
                prevAnswersHandler(answers, data.question_index);
              }}
              prevAnswer={prevAnswers[data.question_index - 1]}
              isCorrectAnswer={(submittedAnswers, currectAnswers) =>
                isCorrectAnswer(
                  submittedAnswers,
                  currectAnswers,
                  data.question_index
                )
              }
            />
          )}
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
              disabled={!isGotoNext}
            >
              {data && data.type === 4 ? (
                data.meta !== null && data.meta.button_title_start === false ? (
                  <Localization text="next" />
                ) : (
                  <Localization text="start" />
                )
              ) : (
                <Localization text="next" />
              )}
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityQuiz;
