import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const DynamicContentBlank = props => {
  const dispatch = useDispatch();
  const history = props.history;
  const { data, arabic } = props;
  const { title, content, title_as_image } = data;
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken);
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  return (
    <div
      className={`content-area ${
        data.meta && data.meta.bg_blue ? 'bg-special text-primary' : ''
      }`}
    >
      <Title
        className={
          data.meta && data.meta.is_covid_page
            ? 'bg-primary '
            : title_as_image
            ? ''
            : 'bg-dark'
        }
      >
        {title_as_image ? (
          <>
            <img src={title} alt="" className="h-100" />
          </>
        ) : (
          <h1
            className={`fs-20 fw-900 ${
              data.meta && data.meta.is_covid_page
                ? 'text-special'
                : 'text-primary'
            } ${arabic}`}
          >
            {title}
          </h1>
        )}
      </Title>

      <div className="justify-content-start">
        <div className="content">
          <HtmlParser>{content}</HtmlParser>
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn radius-0 text-left next-btn fs-14 fw-900 text-uppercase ${
                data.meta && data.meta.is_covid_page
                  ? 'btn-covid'
                  : 'btn-primary'
              }`}
              // style={`${
              //   data.meta && data.meta.is_covid_page? { background: '#228BE5', color: 'white' } : {}
              // }`}
            >
              <Localization text="next" />
              <NextIcon
                color={
                  data.meta && data.meta.is_covid_page ? 'white' : '#495057'
                }
                className="ml-2"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DynamicContentBlank);
