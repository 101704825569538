import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Questions from '../../../components/questions/SurveyQuestion';
import { surveyAnswerFormat } from '../../../utils/utils';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const ActivitySurvey = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, arabic } = props;
  const { title, content, questions } = data;
  const [answers, setAnswers] = useState([]);
  const [qid, setQid] = useState([]);
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const prevAnswersHandler = ans => {
    setAnswers([...ans]);
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    const formatedAnswer = surveyAnswerFormat(answers, qid, data.module_id);
    if (formatedAnswer.submitted_questions.length > 0) {
      // console.log(formatedAnswer);
      axios
        .post(data.survey_submit_url, formatedAnswer, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then(res => {
          if (res.status === 201) {
            // console.log('data submitted successfully');
          }

          // push to next page
          if (data.next_page_url === null) {
            axios
              .post(
                data.next_page_unlock_url,
                {},
                {
                  headers: {
                    Authorization: 'Bearer ' + token,
                  },
                }
              )
              .then(res => {
                history.push(`/${res.data.data.next_page_url.path}`);
              })
              .catch(err => {
                if (err.response.status === 401) {
                  refreshTokenHandler(dispatch, refreshToken);
                }
              });
          } else {
            history.push(`/${data.next_page_url.path}`);
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken);
          }
          // push to next page
          if (data.next_page_url === null) {
            axios
              .post(
                data.next_page_unlock_url,
                {},
                {
                  headers: {
                    Authorization: 'Bearer ' + token,
                  },
                }
              )
              .then(res => {
                history.push(`/${res.data.data.next_page_url.path}`);
              })
              .catch(err => {
                if (err.response.status === 401) {
                  refreshTokenHandler(dispatch, refreshToken);
                }
              });
          } else {
            history.push(`/${data.next_page_url.path}`);
          }
        });
    } else {
      if (data.next_page_url === null) {
        axios
          .post(
            data.next_page_unlock_url,
            {},
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          )
          .then(res => {
            history.push(`/${res.data.data.next_page_url.path}`);
          })
          .catch(err => {
            if (err.response.status === 401) {
              refreshTokenHandler(dispatch, refreshToken);
            }
          });
      } else {
        history.push(`/${data.next_page_url.path}`);
      }
    }
  };

  return (
    <div className="content-area">
      <Title className="bg-dark">
        <p className={`fs-17 text-secondary fw-700 ${arabic}`}>
          <Localization text="activity" />
        </p>
        <h4 className={`fs-20 text-secondary-light fw-900 ${arabic}`}>
          {title}
        </h4>
      </Title>

      <div>
        <div className={`content ${arabic}`}>
          {content && <HtmlParser className={arabic}>{content}</HtmlParser>}
          {questions && (
            <Questions
              questions={questions}
              prevAnswer={answers}
              setQid={setQid}
              prevAnswersHandler={prevAnswersHandler}
              className={arabic}
            />
          )}
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
            >
              <Localization text="next" />
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySurvey;
