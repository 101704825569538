import { useState, useEffect } from 'react';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Textarea from './Textarea';

const Questions = props => {
  const { questions, setIsGotoNext, prevAnswersHandler, isCorrectAnswer, prevAnswer, className } = props;
  const [correctAnswerSet, setCorrectAnswerSet] = useState();

  useEffect(() => {
    if (questions) {
      if(questions.length !== 0) {
        let correct_answers =
        questions.length > 1
          ? questions.map(question => {
              if (question.type === 1) {
                // for checkbox type Question
                return question.correct_answers;
              } else if (question.type === 2) {
                // for radio type Question
                return question.correct_answers[0];
              }
            })
          : questions[0].type === 1
          ? questions[0].correct_answers
          : questions[0].correct_answers[0];

      setCorrectAnswerSet(correct_answers);

      // intialize answer with undefined
      if (prevAnswer === undefined) {
        if (questions.length === 1) {
          prevAnswersHandler(undefined);
        } else {
          let initAns = questions.map(question => undefined);
          prevAnswersHandler([...initAns]);
        }
      }
      }

    }
  }, [questions]);


  useEffect(() => {
    let isUnlock = false;

    if (Array.isArray(prevAnswer)) {
      if (prevAnswer.length > 0) {
        isUnlock = !prevAnswer.flat().some(ans => ans === undefined);
      }
    } else {
      isUnlock = prevAnswer !== undefined;
    }

    setIsGotoNext(isUnlock);
  }, [prevAnswer]);

  const getAnswer = (ans, index) => {
    if (questions.length > 1) {
      if (prevAnswer === undefined) {
        let newAnswerSet = [];
        newAnswerSet[index] = ans;
        prevAnswersHandler(newAnswerSet);
        isCorrectAnswer(newAnswerSet, correctAnswerSet);
        // setIsGotoNext(isUnblockPage(newAnswerSet));
      } else {
        let oldAnswerSet = prevAnswer;
        oldAnswerSet[index] = ans;
        prevAnswersHandler([...oldAnswerSet]);
        isCorrectAnswer(oldAnswerSet, correctAnswerSet);
        // setIsGotoNext(isUnblockPage(oldAnswerSet));
      }
    } else {
      prevAnswersHandler(ans);
      isCorrectAnswer(ans, correctAnswerSet);
      // setIsGotoNext(isUnblockPage(ans));
    }
  };

  const renderQuestion = () => {
    if (questions.length === 1) {
      const question = questions[0];
      switch (question.type) {
        case 1:
          return (
            <div className="mb-3">
              <Checkbox
                prevAnswer={prevAnswer !== undefined ? prevAnswer : []}
                question={question}
                getAnswer={value => getAnswer(value)}
                className={className}
              />
            </div>
          );
        case 2:
          return (
            <div className="mb-3">
              <Radio
                prevAnswer={prevAnswer}
                question={question}
                getAnswer={value => getAnswer(value)}
                className={className}
              />
            </div>
          );
        case 4:
          return (
            <div className="mb-3">
              <Textarea
                question={question}
                getAnswer={value => getAnswer(value)}
                className={className}
              />
            </div>
          );
        default:
          return '';
      }
    } else {
      return questions.map((question, i) => {
        switch (question.type) {
          case 1:
            return (
              <div key={i} className="mb-3">
                <Checkbox
                  question={question}
                  prevAnswer={
                    !Array.isArray(prevAnswer)
                      ? []
                      : prevAnswer[i]
                      ? prevAnswer[i]
                      : []
                  }
                  getAnswer={value => getAnswer(value, i)}
                  className={className}
                />
              </div>
            );
          case 2:
            return (
              <div key={i} className="mb-3">
                <Radio
                  question={question}
                  prevAnswer={
                    Array.isArray(prevAnswer) ? prevAnswer[i] : undefined
                  }
                  getAnswer={value => getAnswer(value, i)}
                  className={className}
                />
              </div>
            );
          case 4:
            return (
              <div key={i} className="mb-3">
                <Textarea
                  question={question}
                  getAnswer={value => getAnswer(value, i)}
                  className={className}
                />
              </div>
            );
          default:
            return '';
        }
      });
    }
  };

  return <>{renderQuestion()}</>;
};

export default Questions;
