import { useEffect, useState } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { BackIcon, NextIcon } from '../../components/ui/icons';
import { validate } from '../../utils/utils';
import Localization from '../../utils/localization';
import translate from '../../utils/translate';

const ForgotPassword = props => {
  const { history } = props;
  const [error, setError] = useState(null);

  const [msg, setMsg] = useState({
    text: '',
    isError: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { token, language } = useSelector(state => state.uiHelper);

  const [forgotInfo, setForgotInfo] = useState({
    username: '',
  });

  const loginHandler = e => {
    e.preventDefault();
    const tempError = validate('forgot', forgotInfo);
    setError({ ...tempError });

    if (!tempError.isError) {
      setIsLoading(true);
      axios
        .post(process.env.REACT_APP_API_URL + '/password/forgot', forgotInfo)
        .then(res => {
          if (res.status === 204) {
            setMsg({
              isError: true,
              text: 'No user found. Please try again with correct username',
            });
          } else {
            console.log(res.status);
            setMsg({
              isError: false,
              text:
                language === 'ar'
                  ? 'شكرًا لك. تم إرسال رابط لإعادة تعيين كلمة المرور إلى عنوان البريد الإلكتروني الذي قمت بتزويده.'
                  : 'Thank you. A link to reset your password has been sent to the email address you provided.',
            });
          }

          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const inputHandler = ({ name, value }) => {
    setForgotInfo({
      ...forgotInfo,
      [name]: value,
    });
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    if (error !== null) {
      const tempError = validate('forgot', forgotInfo);
      setError({ ...tempError });
      if (msg.text !== '') {
        setMsg({ ...msg, text: '' });
      }
    }
  }, [forgotInfo]);

  return (
    <>
      {token !== '' && <Redirect push to="/modules" />}
      <form className="content-area" onSubmit={loginHandler}>
        <div
          className={`title bg-special ${
            (language === 'ar' && 'arabic-text') || ''
          }`}
        >
          <h1 className="fs-20 fw-900 text-primary">
            <Localization text="ItfSefetyAndHealth" />
          </h1>
        </div>

        <div>
          {msg.text && (
            <p
              style={{
                fontSize: '14px',
                color: msg.isError ? 'salmon' : '#28a745',
                fontWeight: '400',
                textAlign: 'center',
                padding: '0 10px',
              }}
            >
              {msg.text}
            </p>
          )}

          <div
            className={`content ${(language === 'ar' && 'arabic-text') || ''}`}
          >
            {isLoading ? (
              <div className="content-loader">
                <div className="loader"></div>
              </div>
            ) : (
              <div className="p-4">
                <div className="form-group">
                  <label htmlFor="user-name" className="fs-20 fw-900">
                    <Localization text="userName" />
                  </label>
                  <input
                    type="text"
                    id="user-name"
                    name="username"
                    className="form-control"
                    value={forgotInfo.username}
                    onChange={e => inputHandler(e.target)}
                  />
                  {error && error.username !== '' ? (
                    <p
                      style={{
                        fontSize: '15px',
                        color: 'salmon',
                        fontWeight: '600',
                      }}
                    >
                      {translate(error.username, language)}
                    </p>
                  ) : null}
                </div>
              </div>
            )}
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                type="submit"
                className="btn btn-special radius-0 text-left next-btn fs-14 text-uppercase fw-900"
                disabled={isLoading}
              >
                <Localization text="forgot" />
                <NextIcon color="#E9ECEF" className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withRouter(ForgotPassword);
