import { useEffect } from 'react';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Textarea from './Textarea';

const Questions = props => {
  const { questions, prevAnswer, prevAnswersHandler, setQid } = props;

  useEffect(() => {
    let qid = questions.map(question => question.id);
    setQid([...qid]);
  }, [questions]);

  const getAnswer = (ans, index) => {
    let oldAnswerSet = prevAnswer;
    if (Array.isArray(ans)) {
      oldAnswerSet[index] = [...ans];
    } else {
      oldAnswerSet[index] = [ans];
    }

    prevAnswersHandler([...oldAnswerSet]);
  };

  return (
    <>
      {questions.map((question, i) => {
        switch (question.type) {
          case 1:
            return (
              <div key={i} className="mb-3">
                <Checkbox
                  prevAnswer={
                    !Array.isArray(prevAnswer)
                      ? []
                      : prevAnswer[i]
                      ? prevAnswer[i]
                      : []
                  }
                  question={question}
                  getAnswer={value => getAnswer(value, i)}
                />
              </div>
            );
          case 2:
            return (
              <div key={i} className="mb-3">
                <Radio
                  prevAnswer={
                    !Array.isArray(prevAnswer)
                      ? undefined
                      : prevAnswer[i]
                      ? prevAnswer[i][0]
                      : undefined
                  }
                  question={question}
                  getAnswer={value => getAnswer(value, i)}
                />
              </div>
            );
          case 4:
            return (
              <div key={i} className="mb-3">
                <Textarea
                  prevAnswer={
                    !Array.isArray(prevAnswer)
                      ? []
                      : prevAnswer[i]
                      ? prevAnswer[i]
                      : []
                  }
                  question={question}
                  getAnswer={value => getAnswer(value, i)}
                />
              </div>
            );
          default:
            return '';
        }
      })}
    </>
  );
};

export default Questions;
