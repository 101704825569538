import axios from 'axios';
import { authTokenDispatch, authRefreshTokenDispatch, expireTimeDispatch } from '../redux/dispatchs/uiHelperDispatch';


const useRefreshToken = (dispatch, refreshToken) => {
  const date = new Date();
  console.log('refreshToken - 4')
  axios
    .post(`${process.env.REACT_APP_API_URL}/refresh`, {
      refresh_token: refreshToken,
    })
    .then(res => {
      date.setTime(date.getTime() + res.data.data.expire_in_seconds * 1000);
      expireTimeDispatch(dispatch, date.toUTCString())
      authTokenDispatch(dispatch, res.data.data.token)
    })
    .catch(error => {
      expireTimeDispatch(dispatch, '')
      authTokenDispatch(dispatch, '')
      authRefreshTokenDispatch(dispatch, '')
    });
}

export default useRefreshToken;