import {
  IS_FAILURE,
  FINAL_ASSESSMENT_INTRO,
  CHANGE_LANGUAGE,
  AUTH_TOKEN,
  NO_HEADER_PAGE,
  AUTH_REFRESH_TOKEN,
  REDIRECT_PAGE_URL,
  LANDSCAPE_MODE_ON,
  AUTH_SESSION,
  EXPIRE_TIME
} from '../type';

export const isFailurePageDispatch = (dispatch, status) => {
  return dispatch({
    type: IS_FAILURE,
    status: status,
  });
};

export const isFinalAssessmentIntroPageDispatch = (dispatch, status) => {
  return dispatch({
    type: FINAL_ASSESSMENT_INTRO,
    status: status,
  });
};

export const changeLanguageDispatch = (dispatch, language) => {
  return dispatch({
    type: CHANGE_LANGUAGE,
    language: language,
  });
};

export const authTokenDispatch = (dispatch, token) => {
  return dispatch({
    type: AUTH_TOKEN,
    token: token,
  });
};

export const authSessionDispatch = (dispatch, status) => {
  return dispatch({
    type: AUTH_SESSION,
    status: status,
  });
};

export const authRefreshTokenDispatch = (dispatch, token) => {
  return dispatch({
    type: AUTH_REFRESH_TOKEN,
    token: token,
  });
};

export const noHeaderPageDispatch = (dispatch, status) => {
  return dispatch({
    type: NO_HEADER_PAGE,
    status: status,
  });
};

export const redirectPageUrlDispatch = (dispatch, url) => {
  return dispatch({
    type: REDIRECT_PAGE_URL,
    url: url,
  });
};

export const landscapeModeDispatch = (dispatch, status) => {
  return dispatch({
    type: LANDSCAPE_MODE_ON,
    status: status,
  });
};

export const expireTimeDispatch = (dispatch, time) => {
    return dispatch({
      type: EXPIRE_TIME,
      time: time,
    });
  };
  