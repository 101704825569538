import Header from '../layout/Header';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  modalContentDispatch,
  modulesDispatch,
} from '../redux/dispatchs/modulesDispatch';

const FullWidthlayout = props => {
  const { bg, children } = props;

  const dispatch = useDispatch();
  const uiHelper = useSelector(state => state.uiHelper);
  const { modules, modalContent, isFetching } = useSelector(
    state => state.modules
  );

  const { language, token } = uiHelper;

  useEffect(() => {
    if (token && !modules && !isFetching) {
      modulesDispatch(dispatch, token, language);
    }

    // if (!modalContent) {
    //   modalContentDispatch(dispatch, language);
    // }
    // modalContentDispatch(dispatch, language);
  }, [language, token]);

  useEffect(() => {
    modalContentDispatch(dispatch, language);
  }, [language]);

  return (
    <div className={`wrapper bg-${bg ? bg : 'special'}`}>
      <Header data={modules} />
      {children}
    </div>
  );
};

export default FullWidthlayout;
