import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Layout
import Boxlayout from './hoc/BoxLayout';
import FullWidthlayout from './hoc/FullWidthLayout';

// Browser History
// import history from './utils/history';

// Redux Store
import { store, persistor } from './redux/store';

// Routes
import routes from './routes/routes';

// CSS
import './scss/style.scss';

function App() {
  // if (window.matchMedia('(display-mode: standalone)').matches) {
  //   alert('display-mode is standalone');
  // } else {
  //   alert('browser mode');
  // }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            {routes.map((route, i) => {
              const { component, path, layout, exact, ...rest } = route;
              const Component = component;
              const Layout =
                layout === 'full-width' ? FullWidthlayout : Boxlayout;
              return (
                <Route key={i} path={path} exact={exact ? exact : true}>
                  <Layout {...rest}>
                    <Component />
                  </Layout>
                </Route>
              );
            })}
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
