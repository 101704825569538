import { MODULES, MODAL_CONTENT } from '../type';
import axios from 'axios';

export const modulesDispatch = (dispatch, token, language) => {
  dispatch({ type: 'FETCHING_MODULES', payload: true });

  return axios
    .get(`${process.env.REACT_APP_API_URL + '/modules'}`, {
      headers: {
        'Accept-Language': language ? language : 'en',
        Authorization: 'Bearer ' + token,
      },
    })
    .then(res => {
      dispatch({
        type: MODULES,
        data: res.data.data.modules,
      });

      dispatch({ type: 'FETCHING_MODULES', payload: false });
    })
    .catch(err => {
      dispatch({ type: 'FETCHING_MODULES', payload: false });
    });
};

export const modalContentDispatch = (dispatch, language) => {
  dispatch({ type: 'FETCHING_MODULES', payload: true });

  return axios
    .get(`${process.env.REACT_APP_API_URL + '/static-pages'}`, {
      headers: {
        'Accept-Language': language ? language : 'en',
      },
    })
    .then(res => {
      dispatch({
        type: MODAL_CONTENT,
        data: res.data.data,
      });

      dispatch({ type: 'FETCHING_MODULES', payload: false });
    })
    .catch(err => {
      dispatch({ type: 'FETCHING_MODULES', payload: false });
    });
};

export const removeModulesDispatch = dispatch => {
  return dispatch({
    type: MODULES,
    data: null,
  });
};
