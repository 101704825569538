import { FormGroup, Label, Input } from 'reactstrap';
import HtmlParser from '../../utils/htmlParser';

const Checkbox = props => {
  const { question, getAnswer, prevAnswer, className } = props;

  const onChangeHandler = value => {
    const newAnswer = new Set(prevAnswer.flat());
    if (newAnswer.has(value)) {
      newAnswer.delete(value);
    } else {
      newAnswer.add(value);
    }
    getAnswer(Array.from(newAnswer).sort());
  };

  return (
    <div>
      {question.genre && (
        <span
          style={{ fontWeight: 'bold', marginBottom: '3px', display: 'block' }}
        >
          {question.genre}
        </span>
      )}
      <HtmlParser className={className}>{question.title}</HtmlParser>
      {Object.entries(question.options !== null && question.options).map(
        (option, i) => {
          return (
            <FormGroup className={`${className} mt-1`} key={i} check>
              <Label check>
                <Input
                  value={option[0]}
                  onChange={e => onChangeHandler(option[0], i, e)}
                  type="checkbox"
                  checked={prevAnswer && prevAnswer.includes(option[0])}
                />{' '}
                {option[1]}
              </Label>
            </FormGroup>
          );
        }
      )}
    </div>
  );
};

export default Checkbox;
