import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import DynamicContentBlank from './page_type/DynamicContentBlank';
import ActivitySurvey from './page_type/ActivitySurvey';
import ActivityQuiz from './page_type/ActivityQuiz';
import ActivitySuccess from './page_type/ActivitySuccess';
import ImageTitlePage from './page_type/ImageTitlePage';
import FinalAssessmentIntro from './page_type/FinalAssessmentIntro';
import FinalAssessment from './page_type/FinalAssessment';
import ActivityFailure from './page_type/ActivityFailure';
import DynamicContentReveal from './page_type/DynamicContentReveal';
import DynamicContentAccordion from './page_type/DynamicContentAccordion';
import ActivityActionableImage from './page_type/ActivityActionableImage';
import ActivityZoomableImage from './page_type/ActivityZoomableImage';
import ActivityCarousal from './page_type/ActivityCarousal';
import ActivityDragAndDrop from './page_type/ActivityDragAndDrop';
import RetryWithWrongQuestion from './page_type/RetryWithWrongQuestion';
import CertificateGeneration from './page_type/CertificateGeneration';
import FinalAssessmentAlmostPass from './page_type/FinalAssessmentAlmostPass';
import refreshTokenHandler from '../../utils/generateToken';

// Dispatchs
import {
  isFailurePageDispatch,
  isFinalAssessmentIntroPageDispatch,
  noHeaderPageDispatch,
  landscapeModeDispatch,
} from '../../redux/dispatchs/uiHelperDispatch';
import useFetchAuthData from '../../utils/useFetchAuthData';

const Page = props => {
  const dispatch = useDispatch();
  const pathName = props.history.location.pathname;
  // const [data, setData] = useState();
  const { data, isLoading, fetch } = useFetchAuthData(pathName);
  const type = data ? data.type : '';
  const [prevAnswers, setPrevAnswers] = useState([]);
  const [result, setResult] = useState([]);
  const [totalCorrectAnwer, setTotalCorrectAnwer] = useState(0);
  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;

  const prevAnswersHandler = (answers, quesNumber) => {
    let tempAnswers = prevAnswers;
    tempAnswers[quesNumber - 1] = answers;
    setPrevAnswers([...tempAnswers]);
  };

  const isCorrectAnswer = (submittedAnswer, correctAnswer, quesNumber) => {
    let isCorrect = _.isEqual(submittedAnswer, correctAnswer);

    let oldResult = result;
    oldResult[quesNumber - 1] = isCorrect;
    setResult([...oldResult]);
  };

  const resetPrevAnswer = () => {
    setPrevAnswers([]);
    setResult([]);
  };

  var isPassed = () => {
    let correctAnswerCounter = 0;

    result.forEach(res => {
      if (res === true) correctAnswerCounter++;
    });

    setTotalCorrectAnwer(correctAnswerCounter);
    if (data.minimum_correct_answers <= correctAnswerCounter) return true;
    else return false;
  };

  const getCollectAnswer = () => {
    let correctAnswerCounter = 0;

    result.forEach(res => {
      if (res === true) correctAnswerCounter++;
    });

    return correctAnswerCounter;
  };

  var isQuizPassed = () => {
    return result.every(res => res === true);
  };

  useEffect(() => {
    fetch();
    window.scrollTo(0, 0);
  }, [pathName]);

  useEffect(() => {
    isFailurePageDispatch(dispatch, type === 12);
    isFinalAssessmentIntroPageDispatch(dispatch, type === 17);
    noHeaderPageDispatch(dispatch, [28, 29].includes(type));
    landscapeModeDispatch(dispatch, data && data.is_landscape);
  }, [dispatch, type, data]);

  return (
    <>
      {[1, 10].includes(type) && (
        <DynamicContentBlank
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 2 && (
        <DynamicContentReveal
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {[13, 25, 26, 27].includes(type) && (
        <ImageTitlePage
          data={data}
          prevAnswersHandler={prevAnswersHandler}
          prevAnswers={prevAnswers}
          isCorrectAnswer={isCorrectAnswer}
          isPassed={isQuizPassed}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}
      {/* Activity Dynamic Content - Quiz */}

      {[4, 5, 7].includes(type) && (
        <ActivityQuiz
          data={data}
          prevAnswersHandler={prevAnswersHandler}
          prevAnswers={prevAnswers}
          isCorrectAnswer={isCorrectAnswer}
          isPassed={isQuizPassed}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {[8].includes(type) && (
        <ActivityCarousal
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {/* Activity Dynamic Content - Survey */}
      {[6, 30].includes(type) && (
        <ActivitySurvey
          data={data}
          prevAnswersHandler={prevAnswersHandler}
          prevAnswers={prevAnswers}
          isCorrectAnswer={isCorrectAnswer}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 9 && (
        <ActivityDragAndDrop
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 11 && (
        <ActivitySuccess
          data={data}
          resetPrevAnswer={resetPrevAnswer}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 12 && (
        <ActivityFailure
          data={data}
          resetPrevAnswer={resetPrevAnswer}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 22 && (
        <FinalAssessmentAlmostPass
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 16 && (
        <DynamicContentAccordion
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {[17, 18].includes(type) && (
        <FinalAssessmentIntro
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 19 && (
        <FinalAssessment
          data={data}
          prevAnswersHandler={prevAnswersHandler}
          prevAnswers={prevAnswers}
          isCorrectAnswer={isCorrectAnswer}
          getCollectAnswer={getCollectAnswer}
          isPassed={isPassed}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 20 && (
        <RetryWithWrongQuestion
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 21 && (
        <CertificateGeneration
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 28 && (
        <ActivityActionableImage
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}

      {type === 29 && (
        <ActivityZoomableImage
          data={data}
          arabic={language === 'ar' ? 'arabic-text' : ''}
        />
      )}
    </>
  );
};

export default withRouter(React.memo(Page));
