import { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { BackIcon, NextIcon } from '../../components/ui/icons';
import Title from '../../layout/Title';
import Localization from '../../utils/localization';
import refreshTokenHandler from '../../utils/generateToken';
import useFetchAuthData from '../../utils/useFetchAuthData';

const SectionStart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;
  const { data, fetch } = useFetchAuthData(window.location.pathname);

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken);
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="content-area">
      <Title className="bg-dark">
        <div className="text-primary">
          {data && !data.is_final_assessment && (
            <p
              className={`fs-17 fw-600 ${
                language === 'ar' ? 'arabic-text' : ''
              }`}
            >
              <Localization text="section" />{' '}
              {data && `${data.module_position}.${data.module_index}`}
            </p>
          )}
          <h1
            className={`fs-20 fw-900 ${language === 'ar' ? 'arabic-text' : ''}`}
          >
            {data && data.title}
          </h1>
        </div>
      </Title>
      {/* buttonToGo={} */}

      <div>
        <div className={`content ${language === 'ar' ? 'arabic-text' : ''}`}>
          <div>
            <img src={data && data.image} className="w-100" alt="" />
          </div>
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-primary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
            >
              <Localization text="next" />
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionStart;
