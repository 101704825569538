import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';

// components
import { BarIcon } from '../components/ui/icons';
import HtmlParser from '../utils/htmlParser';

// Dispatch
import {
  changeLanguageDispatch,
  authTokenDispatch,
  authRefreshTokenDispatch,
} from '../redux/dispatchs/uiHelperDispatch';

import { removeModulesDispatch } from '../redux/dispatchs/modulesDispatch';
import Localization from '../utils/localization';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef(null);
  const [isRedirect, setIsRedirect] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;
  const { modules, modalContent } = useSelector(state => state.modules);

  const [openedModal, setOpenedModal] = useState('');
  const onCloseModal = () => {
    setOpenedModal('');
  };

  const showMenuHandler = () => {
    setShowSidebar(true);
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowSidebar(false);
    }
  };

  const onChangeHandler = e => {
    changeLanguageDispatch(dispatch, e.target.value);
    setShowSidebar(false);
  };

  const moduleHandler = module => {
    history.push(`/${module.is_unlocked ? module.api_url.path : '#'}`);
    setShowSidebar(false);
  };

  const logoutHandler = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL + '/logout'}`,
        {
          token: token,
          refresh_token: refreshToken,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(res => {
        authTokenDispatch(dispatch, '');
        authRefreshTokenDispatch(dispatch, '');
        removeModulesDispatch(dispatch);
        setIsRedirect(true);
      })
      .catch(err => {
        authTokenDispatch(dispatch, '');
        authRefreshTokenDispatch(dispatch, '');
        removeModulesDispatch(dispatch);
        setIsRedirect(true);
      });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <>
      {isRedirect && <Redirect push to="/" />}
      <div className="header">
        <div className="d-flex align-items-center justify-content-between">
          <button className="btn btn-link p-0 menu-button">
            {window.location.pathname !== '/' && (
              <BarIcon color="#e9ecef" onClick={showMenuHandler} />
            )}
            {localStorage.getItem('login') === 'true' && (
              <img
                src="/img/user.png"
                alt=""
                width="70"
                className="ml-3 d-none d-md-block"
              />
            )}
          </button>

          <div className="app-icon ml-3">
            <img
              className="app-icon__logo"
              src={
                window.location.pathname === '/'
                  ? '/img/Seafarers__Trust_line.png'
                  : '/img/Seafarers__Trust_line_white.png'
              }
              alt=""
              srcSet=""
            />
          </div>
        </div>
      </div>
      {showSidebar && (
        <div
          className={`sidebar-menu ${
            (language === 'ar' && 'arabic-text') || ''
          }`}
          ref={ref}
        >
          <a
            style={{ color: '#f59f02' }}
            href={token ? '/modules' : '/'}
            className="h3"
          >
            <Localization text="home" />
          </a>
          {token && (
            <h3>
              <a href="/intro/1" className="text-secondary">
                <Localization text="beforeYouBegin" />
              </a>
            </h3>
          )}
          {/* <h3>
            <Localization text="modules" />
          </h3> */}
          {token && (
            <ul>
              {modules &&
                modules.map((module, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => moduleHandler(module)}
                      className={module.is_unlocked ? '' : 'disabled-module'}
                      style={{ color: '#1864ab' }}
                    >
                      {module.is_final_assessment ? (
                        <Localization text="finalAssessment" />
                      ) : (
                        <>
                          {' '}
                          <Localization text="module" /> {module.position}
                        </>
                      )}
                    </li>
                  );
                })}
            </ul>
          )}
          {/* <h4>Settings</h4> */}
          <h3 style={{ color: '#228be5' }}>
            <Localization text="changeLanguage" />
          </h3>
          <select
            onChange={onChangeHandler}
            value={language !== null ? language : 'en'}
          >
            <option value="en">English</option>
            <option value="ar">اَلْعَرَبِيَّةُ</option>
          </select>
          <div className="mt-4 mb-3">
            <p
              className="pointer hover_underline"
              onClick={() => setOpenedModal('tnc')}
            >
              <Localization text="termsAndCodition" />
            </p>
            <p
              className="pointer hover_underline"
              onClick={() => setOpenedModal('pp')}
            >
              <Localization text="privacyPolicy" />
            </p>
            <p
              className="pointer hover_underline"
              onClick={() => setOpenedModal('about_authors')}
            >
              <Localization text="aboutTheAuthors" />
            </p>
          </div>

          {token && (
            <button
              onClick={logoutHandler}
              className="btn btn-link text-dark p-0 btn-block"
            >
              <Localization text="logout" />
            </button>
          )}
        </div>
      )}

      <Modal open={openedModal === 'tnc'} onClose={onCloseModal} center>
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>
            {modalContent && modalContent.terms_and_condition}
          </HtmlParser>
        </div>
      </Modal>
      <Modal open={openedModal === 'pp'} onClose={onCloseModal} center>
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>{modalContent && modalContent.privacy_policy}</HtmlParser>
        </div>
      </Modal>
      <Modal
        open={openedModal === 'about_authors'}
        onClose={onCloseModal}
        center
      >
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>{modalContent && modalContent.about_author}</HtmlParser>
        </div>
      </Modal>
    </>
  );
};

export default Header;
