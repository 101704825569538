import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// components
import Title from '../../layout/Title';
import { BackIcon, NextIcon } from '../../components/ui/icons';
import Localization from '../../utils/localization';
import useFetchAuthData from '../../utils/useFetchAuthData';

const Modules = props => {
  const { history } = props;
  const pathName = window.location.pathname;
  const uiHelper = useSelector(state => state.uiHelper);
  const { language } = uiHelper;

  const { data, isLoading, fetch } = useFetchAuthData(pathName);

  const onPrevHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    let url = window.location.href;
    if (url[url.length - 1] === '/') {
      url = url.slice(0, url.lastIndexOf('/'));
      window.location.href = url;
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [pathName]);

  return (
    <>
      <Title className="bg-special d-md-none">
        <h5 className="text-primary fw-900">
          <Localization text="ItfSefetyAndHealth" />
        </h5>
      </Title>

      <div className="module-list w-100">
        <div className="container">
          {/* {
                        !mobile && */}

          <div
            className={`row justify-content-between align-items-center highlighedCard ${
              (language === 'ar' && 'arabic-text') || ''
            }`}
          >
            {/* <div className="col-12 col-md-4">
              {data && data.covid ? (
                <Link
                  to={data ? data.covid.api_url.path : '/'}
                  className="covid module pb-5 mt-0"
                >
                  <div>
                    <h6 className="fs-23 fw-900 text-primary">
                      <Localization text="covidTitle" />
                    </h6>
                  </div>
                </Link>
              ) : null}
            </div>

            <div className="col-12 col-md-4">
              <Link to="/intro/1" className="covid module pb-5 mt-0">
                <div>
                  <h6 className="fs-23 fw-900 text-primary">
                    Before you begin
                  </h6>
                </div>
              </Link>P
            </div> */}

            {data &&
              data.cards &&
              data.cards.map(card => (
                <div className="col-12 col-md-4">
                  <Link to={card.api_url.path} className="covid module pb-5">
                    <div>
                      <h6 className="fs-23 fw-900 text-primary">
                        {card.title}
                      </h6>
                    </div>
                  </Link>
                </div>
              ))}

            <div className="col-12 col-md-4 d-none d-md-block">
              <Link
                to={data ? data.resume_learning.path : pathName}
                className="btn bg-white text-special d-flex align-items-center fs-14 fw-900 text-uppercase w-100"
              >
                <Localization text="resumeLearning" />
                <NextIcon color="#1864ab" className="ml-3" />
              </Link>
            </div>
          </div>
          {/* } */}

          <div className="row align-items-center">
            {data &&
              data.modules.map((module, i) => {
                return (
                  <div key={i} className="col-12 col-md-4">
                    <Link
                      to={module.is_unlocked ? module.api_url.path : '#'}
                      replace
                      className={`module bg-primary ${
                        module.is_unlocked ? '' : 'disabled-module'
                      }`}
                    >
                      <div className="module-image">
                        <img src={module.image} alt="" className="w-100" />
                      </div>
                      <div className={module.is_final_assessment ? 'final-assesment' : ''}>
                        <p
                          className={`fw-600 text-dark ${
                            language === 'ar' ? 'arabic-text' : ''
                          }`}
                        >
                          {module.is_final_assessment ? (
                            <Localization text="finalAssessment" />
                          ) : (
                            <>
                              {' '}
                              <Localization text="module" />{' '}
                              <span
                                className={
                                  language === 'ar' ? 'arabic-text' : ''
                                }
                              >
                                {module.position}
                              </span>{' '}
                            </>
                          )}
                        </p>
                        <h6
                          className={`fs-20 fw-900 text-special ${
                            language === 'ar' ? 'arabic-text' : ''
                          }`}
                        >
                          {module.title}
                        </h6>
                        {!module.is_final_assessment && (
                          <div
                            className={`assessment-status ${
                              module.has_passed_assessment ? 'done' : 'pending'
                            }`}
                          >
                            <div className="assessment-status_icon">
                              <img src={`/img/icons/${module.has_passed_assessment ? 'tick' : 'exclamation'}.png`} alt="" srcset="" />
                            </div>
                            <p>
                              {module.has_passed_assessment
                                ? 'Assessment done!'
                                : 'Assessment pending'}{' '}
                            </p>
                          </div>
                        )}
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/* {
                mobile && */}
      <div className="footer d-md-none">
        <div className="d-flex justify-content-between">
          <button
            onClick={onPrevHandler}
            type="button"
            className="btn btn-dark radius-0 back-btn"
          >
            <BackIcon color="#a5d8ff" />
          </button>
          <Link
            to={data ? data.resume_learning.path : pathName}
            type="button"
            className="btn btn-special radius-0 text-left next-btn fs-14 fw-900 text-uppercase"
          >
            <Localization text="resumeLearning" />
            <NextIcon color="#fff" className="ml-3" />
          </Link>
        </div>
      </div>
      {/* } */}
    </>
  );
};

export default withRouter(Modules);
