// Check Value isEmpty Or Not
const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

const isEmail = email => {
  var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return re.test(email);
};

// signup & login form validator
export const validate = (type, data) => {
  if (type === 'login') {
    const { username, password } = data;
    const errors = {
      username: '',
      password: '',
    };

    if (isEmpty(username)) {
      errors.username = 'errorEmptyUserName';
    }

    if (isEmpty(password)) {
      errors.password = 'errorEmptyPassword';
    }

    errors.isError = Object.values(errors).some(error => error !== '');

    return errors;
  } else if (type === 'forgot') {
    const { username } = data;
    const errors = {
      username: '',
    };

    if (isEmpty(username)) {
      errors.username = 'errorEmptyUserName';
    }

    errors.isError = Object.values(errors).some(error => error !== '');

    return errors;
  } else if (type === 'certificate') {
    const { name } = data;
    const errors = {
      name: '',
    };

    if (isEmpty(name)) {
      errors.name = '';
    }

    errors.isError = Object.values(errors).some(error => error !== '');

    return errors;
  } else if (type === 'signup') {
    for (let key in data) {
      if (typeof data[key] !== 'boolean')
        data[key] = !isEmpty(data[key]) ? data[key].trim() : '';
    }

    const {
      username,
      password,
      confirmPassword,
      email,
      portNumber,
      union,
      country,
      age,
      gender,
      tnc,
      pp,
    } = data;

    const errors = {
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      portNumber: '',
      country: '',
      msg: '',
      union: '',
      gender: '',
      age: '',
    };

    if (!tnc || !pp) {
      errors.msg = 'errorTncAndPP';
    }

    if (isEmpty(username)) {
      errors.username = 'errorEmptyUserName';
    }

    if (isEmpty(password)) {
      errors.password = 'errorEmptyPassword';
    } else if (password.length < 6) {
      errors.password = 'errorPasswordLength';
    }

    if (isEmpty(confirmPassword)) {
      errors.confirmPassword = 'errorEmptyConfirmPassword';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'errorPasswordMustMatch';
    }

    if (isEmpty(email)) {
      errors.email = 'errorEmptyEmail';
    } else if (!isEmail(email)) {
      errors.email = 'errorInvalidEmail';
    }

    if (isEmpty(country)) {
      errors.country = 'errorEmptyCountry';
    }

    if (isEmpty(portNumber)) {
      errors.portNumber = 'errorEmptyPortNumber';
    }

    if (isEmpty(union)) {
      errors.union = 'errorEmptyUnion';
    }

    if (isEmpty(age)) {
      errors.age = 'errorEmptyAge';
    }

    if (isEmpty(gender)) {
      errors.gender = 'errorEmptyOption';
    }

    errors.isError = Object.values(errors).some(error => error !== '');

    return errors;
  } else if (type === 'resetPassword') {
    for (let key in data) {
      if (typeof data[key] !== 'boolean')
        data[key] = !isEmpty(data[key]) ? data[key].trim() : '';
    }

    const { password, confirmPassword } = data;

    const errors = {
      password: '',
      confirmPassword: '',
    };

    if (isEmpty(password)) {
      errors.password = 'errorCreatePassword';
    } else if (password.length < 6) {
      errors.password = 'errorPasswordLength';
    }

    if (isEmpty(confirmPassword)) {
      errors.confirmPassword = 'errorCreateConfirmPassword';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'errorPasswordMustMatch';
    }

    errors.isError = Object.values(errors).some(error => error !== '');

    return errors;
  }
};

export const surveyAnswerFormat = (answers, qid, moduleId) => {
  const formatAnswer = answers.map((answer, index) => {
    if (answer !== undefined) {
      return {
        question_id: qid[index],
        answer: answer,
      };
    }
  });

  const newAnswers = formatAnswer.filter(answer => answer !== undefined);

  return {
    module_id: moduleId,
    submitted_questions: newAnswers,
  };
};

export const getCookie = cname => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
