import { useSelector } from 'react-redux';

const Title = props => {
  const { language } = useSelector(state => state.uiHelper);
  const { className = '', children, ...rest } = props;

  return (
    <div
      className={`title ${className} ${
        (language === 'ar' && 'arabic-text') || ''
      }`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Title;
