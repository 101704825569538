import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Questions from '../../../components/questions/Questions';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const DynamicContentReveal = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, arabic } = props;
  const { title, reveal_text, content, questions } = data;
  const [textRevealed, setTextRevealed] = useState(false);
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const textRevealHandler = () => {
    setTextRevealed(true);
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          setTextRevealed(false)
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken)
          }
        });
    } else {
      setTextRevealed(false)
      history.push(`/${data.next_page_url.path}`);
    }
  };

  return (
    <div className="content-area">
      <Title>
        <img src={title} alt="" className="h-100" />
      </Title>

      <div>
        <div className="content">
          {content && <HtmlParser className={arabic}>{content}</HtmlParser>}
          {textRevealed && (
            <p className={`text-primary fs-20 fw-700 ${arabic}`}>
              {reveal_text}
            </p>
          )}
          {questions && (
            <Questions
              className={arabic}
              questions={questions}
            />
          )}
        </div>

        {textRevealed ? (
          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={nextHandler}
                className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
              >
                <Localization text="next" />
                <NextIcon color="#495057" className="ml-2" />
              </button>
            </div>
          </div>
        ) : (
          <div className="footer failed-footer reveal-footer">
            <div className="d-flex justify-content-end">
              <button
                onClick={textRevealHandler}
                type="button"
                className="btn btn-primary radius-0 text-right next-btn fs-14 text-uppercase fw-900"
              >
                Reveal
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicContentReveal;
