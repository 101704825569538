// Pages
import Home from '../containers/Home/Home';
import Login from '../containers/Login/Login';
import ForgotPassword from '../containers/Login/ForgotPassword';
import ResetPassword from '../containers/Login/ResetPassword';
import Welcome from '../containers/Welcome/Welcome';
import Modules from '../containers/Modules/Modules';
import SectionStart from '../containers/SectionStart/SectionStart';
import Overview from '../containers/Overview/Overview';
import Page from '../containers/Page/Page';
import Success from '../containers/Success';
import Registration from '../containers/Registration/Registration';
import verifyEmail from '../containers/Registration/VerifyEmail';
import Intro from '../containers/Intro';
// import Promo from '../containers/Promo';

const routes = [
  {
    path: '/',
    component: Home,
    className: 'no-footer',
    bg: window.matchMedia('(display-mode: standalone)').matches
      ? 'dark'
      : 'promo',
    layout: window.matchMedia('(display-mode: standalone)').matches
      ? ''
      : 'full-width',
  },
  // {
  //   path: '/xyz',
  //   component: Promo,
  //   // className: 'no-footer',
  //   layout: 'full-width',
  //   bg: 'promo',
  // },
  {
    path: '/login',
    component: Login,
    bg: 'dark',
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    bg: 'dark',
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    bg: 'dark',
  },
  {
    path: '/registration',
    component: Registration,
    bg: 'secondary',
  },
  {
    path: '/verify-email',
    component: verifyEmail,
    layout: 'full-width',
  },
  {
    path: '/intro/:id',
    component: Intro,
    isAuth: true,
    bg: 'secondary',
    wrapperClass: 'intro-page',
  },
  {
    path: '/modules/:id',
    component: Overview,
    isAuth: true,
  },
  {
    path: '/modules/:id/sections/:id',
    component: SectionStart,
    className: 'section-start',
    isAuth: true,
  },
  {
    path: '/modules/:id/sections/:id/pages/:id',
    component: Page,
    commonPage: true,
    isAuth: true,
  },
  {
    path: '/welcome',
    component: Welcome,
    isAuth: true,
  },
  {
    path: '/modules',
    component: Modules,
    layout: 'full-width',
    isAuth: true,
  },
  {
    path: '/section-start',
    component: SectionStart,
    className: 'section-start',
    isAuth: true,
  },
  {
    path: '/success',
    component: Success,
    isAuth: true,
  },
];

export default routes;
