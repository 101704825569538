import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Title from '../../../layout/Title';
import { BackIcon, NextIcon, DownIcon } from '../../../components/ui/icons';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const DynamicContentAccordion = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, arabic } = props;
  const { title, accordian } = data;
  const accordion = JSON.parse(accordian);
  const [isOpen, setIsOpen] = useState('');
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const toggle = key => {
    if (isOpen === key) {
      setIsOpen('');
    } else {
      setIsOpen(key);
    }
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken)
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  return (
    <div className="content-area">
      <Title className="bg-dark">
        <h4 className={`fs-20 text-secondary-light fw-900 ${arabic}`}>
          {title}
        </h4>
      </Title>

      <div className="justify-content-between">
        <div className="content p-0">
          <div className="pt-2">
            {accordion &&
              accordion.map((items, i) => {
                return (
                  <div key={i} className="mb-1">
                    <div
                      className={`d-flex align-items-center justify-content-between py-2 px-3 pointer ${arabic}`}
                      onClick={() => toggle(i)}
                      block
                      style={{
                        background: items.color,
                        borderColor: items.color,
                      }}
                    >
                      {items.label}{' '}
                      <DownIcon
                        color="#e9ecef"
                        style={{
                          transform: isOpen === i ? 'rotate(180deg)' : '',
                        }}
                      />
                    </div>
                    <Collapse isOpen={isOpen === i}>
                      {Object.values(items.options).map((item, i) => {
                        return (
                          <p
                            key={i}
                            className={`btn radius-0 mt-1 ${arabic}`}
                            style={{
                              background: `${items.color}80`,
                            }}
                          >
                            {item}
                          </p>
                        );
                      })}
                    </Collapse>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
            >
              <Localization text="next" />
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicContentAccordion;
