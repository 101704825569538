import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Localization from '../../utils/localization';
import translate from '../../utils/translate';

import Title from '../../layout/Title';
import { BackIcon, NextIcon } from '../../components/ui/icons';
import { validate } from '../../utils/utils';

const ResetPassword = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [resetInfo, setResetInfo] = useState({
    password: '',
    confirmPassword: '',
  });
  const { token: user_token, language } = useSelector(state => state.uiHelper);
  const { token, username } = queryString.parse(window.location.search);

  useEffect(() => {
    if (error !== null) {
      const tempError = validate('resetPassword', resetInfo);
      setError({ ...tempError });
    }
  }, [resetInfo]);

  const resetHandler = () => {
    const tempError = validate('resetPassword', resetInfo);
    setError({ ...tempError });

    if (!tempError.isError) {
      let options = {
        username: username,
        token: token,
        password: resetInfo.confirmPassword,
        password_confirmation: resetInfo.confirmPassword,
      };

      axios
        .post(process.env.REACT_APP_API_URL + '/password/forgot-set', options)
        .then(res => {
          history.push(
            '/login?redirectMsg=Password Reset successfully! Please login with new password to continue the course'
          );
        })
        .catch(err => {
          history.push(
            '/login?isRedirectErrorMsg=true&redirectMsg=Password Reset failed! Please try again...'
          );
        });
    }
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  const inputHandler = e => {
    const { name, value } = e.target;

    setResetInfo({
      ...resetInfo,
      [name]: value,
    });
  };

  return (
    <>
      {user_token !== '' && <Redirect push to="/modules" />}
      <div className="content-area">
        <Title className="bg-dark">
          <h1 className="fs-20 fw-900 text-primary">
            <Localization text="ItfSefetyAndHealth" />
          </h1>
          <h5 className="fw-900 text-secondary">Reset Password</h5>
        </Title>
        <div>
          <div
            className={`content ${(language === 'ar' && 'arabic-text') || ''}`}
          >
            <form action="#" method="POST" className="p-4">
              <div className="form-group">
                <label htmlFor="password" className="fs-20 fw-900">
                  <Localization text="password" />
                  <span>*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={resetInfo.password}
                  onChange={e => inputHandler(e)}
                  required
                />
                {error && error.password !== '' ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.password, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="confirm-password" className="fs-20 fw-900">
                  <Localization text="confirmPassword" />
                  <span>*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm-password"
                  name="confirmPassword"
                  value={resetInfo.confirmPassword}
                  onChange={e => inputHandler(e)}
                  required
                />
                {error && error.confirmPassword !== '' ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.confirmPassword, language)}
                  </p>
                ) : null}
              </div>
            </form>
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={resetHandler}
                className="btn btn-special radius-0 text-left next-btn fs-14 text-uppercase fw-900"
              >
                Reset Password
                <NextIcon color="#E9ECEF" className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
