import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';
import { validate } from '../../../utils/utils';

const ActivitySuccess = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, arabic } = props;
  const { title, content } = data;
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken, language } = uiHelper;

  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [response, setResponse] = useState({
    isDone: false,
    isLoading: false,
    content: '',
  });

  const onPrevHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    if (error !== null) {
      const tempError = validate('certificate', { name });
      setError({ ...tempError });
    }
  }, [name]);

  const nextHandler = () => {
    if (response.isDone) {
      history.push(`/modules`);
    } else {
      const tempError = validate('certificate', { name });
      setError({ ...tempError });

      if (!tempError.isError) {
        setResponse({
          ...response,
          isDone: false,
          isLoading: true,
          content: '',
        });

        axios
          .post(
            process.env.REACT_APP_API_URL + '/final-assessment-certificate',
            {
              module_id: data.module_id,
              name: name,
            },
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Accept-Language': language ? language : 'en',
              },
            }
          )
          .then(res => {
            setResponse({
              ...response,
              isDone: true,
              isLoading: false,
              content: res.data.data && res.data.data.content,
            });
          })
          .catch(err => {
            if (err.response.status === 401) {
              refreshTokenHandler(dispatch, refreshToken);
            }
          });
      }
    }
  };

  return (
    <div
      className={`content-area ${data.is_landscape ? 'landscape-mode' : ''}`}
    >
      {response.isDone ? (
        <Title>
          <img src="/img/success.jpeg" alt="" />
        </Title>
      ) : (
        <div className="title bg-dark text-primary">
          <h1 className="fs-20 fw-900 ">{title}</h1>
        </div>
      )}

      <div>
        <div className="content">
          {response.isLoading && (
            <div className="content-loader">
              <div className="loader"></div>
            </div>
          )}

          {!response.isLoading && response.isDone && (
            <HtmlParser className={arabic}>{response.content}</HtmlParser>
          )}

          {!response.isDone && !response.isLoading && (
            <div className="p-4">
              <div className="form-group">
                <label htmlFor="name" className="fs-20 fw-900">
                  <Localization text="name" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />

                {error && error.name !== '' && (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
              disabled={response.isLoading}
            >
              {response.isDone ? (
                <Localization text="returnToHomePage" />
              ) : (
                <Localization text="receiveCertificate" />
              )}
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySuccess;
