import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { BackIcon, NextIcon } from '../components/ui/icons';
import Localization from '../utils/localization';

const Footer = props => {
  const history = useHistory();
  const { buttonToGo, finalAssessment, btnColor, btnText, iconColor } = props;

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (props.gotoNext === undefined || props.gotoNext) {
      history.push(`/${buttonToGo}`);
    }
  };

  return (
    <div className="footer">
      <div className="d-flex justify-content-between">
        <button
          onClick={onPrevHandler}
          type="button"
          className="btn btn-dark radius-0 back-btn"
        >
          <BackIcon color="#a5d8ff" />
        </button>

        {finalAssessment && (
          <Link
            to="/"
            type="button"
            className="btn btn-secondary radius-0 check-btn"
          >
            Check
          </Link>
        )}

        <button
          onClick={nextHandler}
          className={`btn btn-${
            btnColor ? btnColor : 'primary'
          } radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
        >
          {btnText ? btnText : <Localization text="next" />}
          <NextIcon
            color={iconColor ? iconColor : '#495057'}
            className="ml-2"
          />
        </button>
      </div>
    </div>
  );
};

export const FailedFooter = props => {
  const history = useHistory();
  const { buttonToGo, ...rest } = props;

  const retryHandler = () => {
    history.push(`/${buttonToGo}`);
  };

  return (
    <div className="footer failed-footer" {...rest}>
      <div className="d-flex justify-content-between">
        <button
          onClick={retryHandler}
          type="button"
          className="btn btn-primary radius-0 text-right next-btn"
        >
          <img src="/img/icons/retry.svg" alt="" className="mr-3" />
          Retry
        </button>
      </div>
    </div>
  );
};

export default Footer;
