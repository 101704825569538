import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import Title from '../layout/Title';
import Localization from '../utils/localization';
import HtmlParser from '../utils/htmlParser';
import refreshTokenHandler from '../utils/generateToken';
import { BackIcon, NextIcon } from '../components/ui/icons';
import useFetchAuthData from '../utils/useFetchAuthData';


const Intro = props => {
  const dispatch = useDispatch();
  const history = props.history;
  const pathName = props.history.location.pathname;
  const {data, isLoading, fetch} = useFetchAuthData(pathName)
  const uiHelper = useSelector(state=>state.uiHelper)
  const { language, token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken)
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  useEffect(() => {
    fetch();
  }, [ pathName]);

  return (
    <>
      <div className="content-area bg-special text-primary">
        <Title className="bg-primary">
          <h1 className="fs-20 fw-900 text-special">
            {data && data.title}
          </h1>
        </Title>

        <div>
          <div className="content">
            {/* <div className="video" onClick={toggle}>
              <img src="img/image.jpg" alt="" />
            </div> */}
            {
              data && data.content &&
              <HtmlParser className={language === 'ar' ? 'arabic-text' : ''}>
                {data.content}
              </HtmlParser>
            }
          </div>
          
          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={nextHandler}
                className={`btn radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
                style={{background: '#228BE5', color: 'white'}}
              >
                <Localization text="next" />
                <NextIcon
                  color='white'
                  className="ml-2"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalBody>
          <div className="modal-body p-0">
            <iframe
              title="Nothing"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/xvi1TMkrH20"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </ModalBody>
      </Modal> */}
    </>
  );
};

export default withRouter(Intro);
