import Title from '../layout/Title';
import { useHistory } from 'react-router-dom';
import { BackIcon, NextIcon } from '../components/ui/icons';
import Localization from '../utils/localization';

const Success = () => {
  const history = useHistory();

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    history.push(`/modules`);
  };

  return (
    <div className="content-area">
      <Title>
        <img src="/img/success.jpg" alt="" />
      </Title>

      <div>
        <div className="content">
          <div className="p-4">
            <h3 className="fs-27 fw-900 text-center text-primary">Success!</h3>
            <p className="fs-23 fw-600 mt-3">
              Now you can start learning using this app!
              <br />
              <br />
              Login now!
            </p>
          </div>
        </div>

        <div className="footer">
          <div className="d-flex justify-content-between">
            <button
              onClick={onPrevHandler}
              type="button"
              className="btn btn-dark radius-0 back-btn"
            >
              <BackIcon color="#a5d8ff" />
            </button>

            <button
              onClick={nextHandler}
              className={`btn btn-primary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
            >
              <Localization text="next" />
              <NextIcon color="#495057" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
