import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Modal } from 'react-responsive-modal';

import Title from '../../layout/Title';
import { BackIcon, NextIcon } from '../../components/ui/icons';
import { validate } from '../../utils/utils';
import Localization from '../../utils/localization';
import HtmlParser from '../../utils/htmlParser';
import translate from '../../utils/translate';

import 'react-responsive-modal/styles.css';

const Registration = () => {
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [ports, setPorts] = useState([]);
  const [unions, setUnions] = useState([]);
  const [error, setError] = useState(null);

  const [signupInfo, setSignupInfo] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    portNumber: '',
    union: '',
    country: '',
    tnc: false,
    pp: false,
    age: '',
    gender: '',
  });

  const { token, language } = useSelector(state => state.uiHelper);
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const [tncModal, setTNCModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const onCloseTncModal = () => {
    setTNCModal(false);
  };
  const onClosePrivacyModal = () => {
    setPrivacyModal(false);
  };

  const onOpenTncModal = () => {
    setPrivacyModal(false);
    setTNCModal(true);
  };

  const onOpenPrivacyModal = () => {
    setTNCModal(false);
    setPrivacyModal(true);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/tnc', {
        headers: {
          Authorization: 'Bearer ' + token,
          'Accept-Language': language ? language : 'en',
        },
      })
      .then(res => {
        setTermsAndCondition(res.data.data);
      })
      .catch(err => {});

    axios
      .get(process.env.REACT_APP_API_URL + '/pp', {
        headers: {
          Authorization: 'Bearer ' + token,
          'Accept-Language': language ? language : 'en',
        },
      })
      .then(res => {
        setPrivacyPolicy(res.data.data);
      })
      .catch(err => {});

    axios
      .get(process.env.REACT_APP_API_URL + '/country-ports', {
        headers: {
          Authorization: 'Bearer ' + token,
          'Accept-Language': language ? language : 'en',
        },
      })
      .then(res => {
        if (res.data.is_data) {
          setCountries([...res.data.data]);
        }
      })
      .catch(err => console.log(err));
  }, [language]);

  useEffect(() => {
    if (signupInfo.country && countries) {
      console.log(countries, signupInfo.country);
      let selectContry = countries.filter(country => {
        return country.id === parseInt(signupInfo.country);
      });

      setPorts([...selectContry[0].ports]);
      setUnions([...selectContry[0].unions]);
    }
  }, [countries]);

  const signupHandler = () => {
    const tempError = validate('signup', signupInfo);
    setError({ ...tempError });

    if (!tempError.isError) {
      let options = {
        username: signupInfo.username,
        password: signupInfo.password,
        password_confirmation: signupInfo.confirmPassword,
        email: signupInfo.email,
        port_id: signupInfo.portNumber,
        union_id: signupInfo.union,
        country: signupInfo.country,
        tnc: true,
        pp: true,
        age: signupInfo.age === 'null' ? null : signupInfo.age,
        gender: signupInfo.gender === 'null' ? null : signupInfo.gender,
      };

      axios
        .post(process.env.REACT_APP_API_URL + '/registration', options)
        .then(res => {
          history.push(
            '/login?redirectMsg=Please check your email to verify your account.'
          );
        })
        .catch(err => {
          const errorData = err.response.data.data;

          let genError = {
            email: '',
            username: '',
            msg: err.response.data.message,
          };

          if (errorData) {
            if (errorData.email) genError.email = errorData.email[0];
            if (errorData.username) genError.username = errorData.username[0];
          }
          setError({ ...error, ...genError });
        });
    }
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  const inputHandler = e => {
    const { name, value } = e.target;

    if (name === 'country') {
      setSignupInfo({
        ...signupInfo,
        [name]: value,
        portNumber: '',
        union: '',
      });

      let selectContry = countries.filter(country => {
        return country.id === parseInt(value);
      });

      setPorts([...selectContry[0].ports]);
      setUnions([...selectContry[0].unions]);
    } else if (name === 'tnc' || name === 'pp') {
      setSignupInfo({
        ...signupInfo,
        [name]: value === 'true' ? false : true,
      });
    } else {
      setSignupInfo({
        ...signupInfo,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (error !== null) {
      const tempError = validate('signup', signupInfo);
      setError({ ...tempError });
    }
  }, [signupInfo]);

  return (
    <>
      {token !== '' && <Redirect push to="/modules" />}
      <div className="content-area">
        <Title className="bg-dark">
          <h1 className="fs-20 fw-900 text-primary">
            {/* ITF Occupational Safety and Health Training */}
            <Localization text="ItfSefetyAndHealth" />
          </h1>
          <h5 className="fw-900 text-secondary">
            <Localization text="registration" />
          </h5>
        </Title>
        <div>
          <div
            className={`content ${(language === 'ar' && 'arabic-text') || ''}`}
          >
            <form action="#" method="POST" className="p-4">
              <h5>
                <span className="required-star">*</span>{' '}
                <Localization text="fillInTheFormText" />
              </h5>
              <div className="form-group">
                <label htmlFor="user-name" className="fs-20 fw-900">
                  <Localization text="userName" /> <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="user-name"
                  name="username"
                  value={signupInfo.username}
                  onChange={e => inputHandler(e)}
                  required
                />
                {error && error.username ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {error.username === 'errorEmptyUserName'
                      ? translate(error.username, language)
                      : error.username}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password" className="fs-20 fw-900">
                  <Localization text="password" /> <span>*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={signupInfo.password}
                  onChange={e => inputHandler(e)}
                  required
                />
                {error && error.password ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.password, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="confirm-password" className="fs-20 fw-900">
                  <Localization text="confirmPassword" /> <span>*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm-password"
                  name="confirmPassword"
                  value={signupInfo.confirmPassword}
                  onChange={e => inputHandler(e)}
                  required
                />
                {error && error.confirmPassword ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.confirmPassword, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="email" className="fs-20 fw-900 mb-0">
                  <Localization text="email" /> <span>*</span>
                </label>
                {/* <small id="emailHelp" className="form-text text-muted mb-2">
                  Optional: It will help you to receive new information about
                  courses and recover password.
                </small> */}
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={signupInfo.email}
                  onChange={e => inputHandler(e)}
                  aria-describedby="emailHelp"
                />
                {error && error.email ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {/* {translate(error.email, language)} */}
                    {error.email === 'errorEmptyEmail' ||
                    error.email === 'errorInvalidEmail'
                      ? translate(error.email, language)
                      : error.email}
                  </p>
                ) : null}
              </div>
              {/* Gender */}
              <div className="form-group">
                <label htmlFor="gender" className="fs-20 fw-900">
                  <Localization text="gender" /> <span>*</span>
                </label>

                <select
                  className="form-control"
                  id="gender"
                  name="gender"
                  placeholder="select Gender"
                  onChange={e => inputHandler(e)}
                  required
                >
                  <option
                    disabled={signupInfo.gender !== ''}
                    selected={signupInfo.gender === ''}
                  >
                    {translate('select', language)}
                  </option>
                  <option value="Male">{translate('male', language)}</option>
                  <option value="Female">
                    {translate('female', language)}
                  </option>
                  <option value="Other">{translate('other', language)}</option>
                  <option value="null">{translate('notSay', language)}</option>
                </select>

                {error && error.gender ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.gender, language)}
                  </p>
                ) : null}
              </div>

              {/* Age */}
              <div className="form-group">
                <label htmlFor="age" className="fs-20 fw-900">
                  <Localization text="age" /> <span>*</span>
                </label>

                <select
                  className="form-control"
                  id="age"
                  name="age"
                  placeholder="select Age"
                  onChange={e => inputHandler(e)}
                  required
                >
                  <option
                    disabled={signupInfo.age !== ''}
                    selected={signupInfo.age === ''}
                  >
                    {translate('select', language)}
                  </option>
                  <option value="35 and under">
                    {translate('under35', language)}
                  </option>
                  <option value="Older than 35">
                    {translate('over35', language)}
                  </option>
                  <option value="null">{translate('notSay', language)}</option>
                </select>

                {error && error.age ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.age, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="country" className="fs-20 fw-900">
                  <Localization text="country" /> <span>*</span>
                </label>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  placeholder="select Country"
                  onChange={e => inputHandler(e)}
                  value={signupInfo.country}
                  required
                >
                  <option disabled={signupInfo.country !== ''}>
                    {translate('select', language)}
                  </option>
                  {countries.map((country, i) => (
                    <option key={i} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>

                {error && error.country ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.country, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="port-number" className="fs-20 fw-900">
                  <Localization text="portName" /> <span>*</span>
                </label>

                <select
                  className="form-control"
                  id="port-number"
                  name="portNumber"
                  placeholder="select Port"
                  onChange={e => inputHandler(e)}
                  value={signupInfo.portNumber}
                  required
                >
                  <option
                    disabled={signupInfo.portNumber !== ''}
                    selected={signupInfo.portNumber === ''}
                  >
                    {translate('select', language)}
                  </option>
                  {ports.map((port, i) => (
                    <option key={i} value={port.id}>
                      {port.name}
                    </option>
                  ))}
                </select>

                {error && error.portNumber ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.portNumber, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="union" className="fs-20 fw-900">
                  <Localization text="unionName" /> <span>*</span>
                </label>

                <select
                  className="form-control"
                  id="union"
                  name="union"
                  placeholder="select Union"
                  onChange={e => inputHandler(e)}
                  value={signupInfo.union}
                  required
                >
                  <option
                    disabled={signupInfo.union !== ''}
                    selected={signupInfo.union === ''}
                  >
                    {translate('select', language)}
                  </option>
                  {unions.map((union, i) => (
                    <option key={i} value={union.id}>
                      {union.short_name}
                    </option>
                  ))}
                </select>

                {error && error.union ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.union, language)}
                  </p>
                ) : null}
              </div>
              <div className="form-check form-check-inline">
                <input
                  id="tnc"
                  name="tnc"
                  type="checkbox"
                  value={signupInfo.tnc}
                  onChange={inputHandler}
                />
                <label
                  htmlFor="tnc"
                  className="ml-2"
                  style={{
                    fontSize: '17px',
                    marginBottom: 0,
                  }}
                >
                  <Localization text="iAgreeToThe" />{' '}
                  <Button
                    className="text-secondary bg-transparent border-0 btn-link"
                    onClick={onOpenTncModal}
                    style={{ display: 'contents' }}
                  >
                    <Localization text="termsAndCodition" />
                  </Button>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  id="pp"
                  type="checkbox"
                  name="pp"
                  value={signupInfo.pp}
                  onChange={inputHandler}
                />
                <label
                  htmlFor="pp"
                  className="ml-2"
                  style={{
                    fontSize: '17px',
                    marginBottom: 0,
                  }}
                >
                  <Localization text="iAgreeToThe" />{' '}
                  <Button
                    className="text-secondary bg-transparent border-0 btn-link"
                    onClick={onOpenPrivacyModal}
                    style={{ display: 'contents' }}
                  >
                    <Localization text="privacyPolicy" />
                  </Button>
                </label>
              </div>
            </form>

            {error && error.msg ? (
              <p
                style={{
                  fontSize: '18px',
                  color: 'salmon',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {error.msg === 'errorTncAndPP'
                  ? translate(error.msg, language)
                  : error.msg}
              </p>
            ) : null}
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={signupHandler}
                className="btn btn-special radius-0 text-left next-btn fs-14 text-uppercase fw-900"
              >
                <Localization text="signup" />{' '}
                <NextIcon color="#E9ECEF" className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal open={tncModal} onClose={onCloseTncModal} center>
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>{termsAndCondition}</HtmlParser>
        </div>
      </Modal>

      <Modal open={privacyModal} onClose={onClosePrivacyModal} center>
        <div className={language === 'ar' ? 'text-right' : ''}>
          <HtmlParser>{privacyPolicy}</HtmlParser>
        </div>
      </Modal>
    </>
  );
};

export default Registration;
