import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import refreshTokenHandler from '../../../utils/generateToken';

const ActivityActionableImage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = props;
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(
          data.next_page_unlock_url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken)
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  useEffect(() => {
    var autoPageChange;
    if (data && !data.marker.status) {
      autoPageChange = setTimeout(() => {
        nextHandler();
      }, 1500);
    }

    return () => {
      clearTimeout(autoPageChange);
    };
  }, [data]);

  return (
    <div
      className={`image-activity ${!data.marker.status ? 'pointer' : ''}`}
      onClick={!data.marker.status ? nextHandler : () => {}}
    >
      <img src={data.title} alt="" />

      {data.marker.status ? (
        <button
          onClick={nextHandler}
          type="button"
          className="btn btn-link marker"
          style={{
            left: data.marker.position.x + '%',
            top: data.marker.position.y + '%',
          }}
        >
          <img src={data.marker.url} alt="" />
        </button>
      ) : (
        <button
          onClick={nextHandler}
          type="button"
          className="btn btn-dark radius-0 next-btn"
        >
          <NextIcon color="#A5D8FF" />
        </button>
      )}

      <button
        onClick={onPrevHandler}
        type="button"
        className="btn btn-dark radius-0 back-btn"
      >
        <BackIcon color="#A5D8FF" />
      </button>
    </div>
  );
};

export default ActivityActionableImage;
