import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
  modalContentDispatch,
  modulesDispatch,
} from '../redux/dispatchs/modulesDispatch';

// Components
import Header from '../layout/Header';

const BoxLayout = props => {
  const { bg, children, wrapperClass, commonPage, isAuth, ...rest } = props;

  const dispatch = useDispatch();
  const uiHelper = useSelector(state => state.uiHelper);

  const { modules, modalContent, isFetching } = useSelector(
    state => state.modules
  );

  const { language, token } = uiHelper;

  useEffect(() => {
    if (token && !modules && !isFetching) {
      modulesDispatch(dispatch, token, language);
    }

    // if (!modalContent) {
    //   modalContentDispatch(dispatch, language);
    // }
  }, [language, token]);

  useEffect(() => {
    modalContentDispatch(dispatch, language);
  }, [language]);

  return (
    <div
      className={`
      wrapper bg-${bg ? bg : 'special'}
      ${commonPage && uiHelper.noHeader ? 'no-header-page' : ''}
      ${commonPage && uiHelper.landscapePage ? 'landscape-page' : ''}
      ${wrapperClass}
    `}
    >
      <Header data={modules} />

      <main
        className={`
          ${commonPage && uiHelper.failurPage ? 'bg-dark' : ''} 
          ${
            commonPage && uiHelper.finalAssessmentIntroPage
              ? 'section-start'
              : ''
          }
        `}
        {...rest}
      >
        {children}
      </main>
    </div>
  );
};

export default BoxLayout;
