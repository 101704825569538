import axios from 'axios';
import { useEffect, useState } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

// Components
import { BackIcon, NextIcon } from '../../components/ui/icons';
import { validate } from '../../utils/utils';
import Localization from '../../utils/localization';
import {
  authTokenDispatch,
  authRefreshTokenDispatch,
  expireTimeDispatch,
} from '../../redux/dispatchs/uiHelperDispatch';

import translate from '../../utils/translate';

const Login = props => {
  const dispatch = useDispatch();
  const { history } = props;
  const date = new Date();
  const [error, setError] = useState(null);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const uiHelper = useSelector(state => state.uiHelper);
  const { redirectUrl } = uiHelper;
  const { redirectMsg, isRedirectErrorMsg } = queryString.parse(
    window.location.search
  );

  const [loginInfo, setLoginInfo] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    if (error !== null) {
      const tempError = validate('login', loginInfo);
      setError({ ...tempError });
    }
  }, [loginInfo]);

  const loginHandler = e => {
    e.preventDefault();
    const tempError = validate('login', loginInfo);
    setError({ ...tempError });

    if (!tempError.isError) {
      axios
        .post(process.env.REACT_APP_API_URL + '/login', loginInfo)
        .then(res => {
          date.setTime(date.getTime() + res.data.data.expire_in_seconds * 1000);
          expireTimeDispatch(dispatch, date.toUTCString());
          authTokenDispatch(dispatch, res.data.data.token);
          authRefreshTokenDispatch(dispatch, res.data.data.refresh_token);
          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push('/welcome');
          }
        })
        .catch(err => {
          setIsLoginFailed(true);
        });
    }
  };

  const inputHandler = ({ name, value }) => {
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  return (
    <>
      {uiHelper.token !== '' && <Redirect push to="/modules" />}
      <form className="content-area" onSubmit={loginHandler}>
        <div
          className={`title bg-special ${
            (uiHelper.language === 'ar' && 'arabic-text') || ''
          }`}
        >
          <h1 className="fs-20 fw-900 text-primary">
            <Localization text="ItfSefetyAndHealth" />
          </h1>
        </div>

        <div>
          <div className="p-4">
            {redirectMsg && (
              <p
                style={{
                  fontSize: '14px',
                  color: isRedirectErrorMsg === 'true' ? 'salmon' : '#28a745',
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {redirectMsg}
              </p>
            )}

            <div
              className={`content ${
                (uiHelper.language === 'ar' && 'arabic-text') || ''
              }`}
            >
              {/* Login Form */}

              <div className="form-group">
                <label htmlFor="user-name" className="fs-20 fw-900">
                  <Localization text="userName" />
                </label>
                <input
                  type="text"
                  id="user-name"
                  name="username"
                  className="form-control"
                  value={loginInfo.username}
                  onChange={e => inputHandler(e.target)}
                />
                {error && error.username !== '' ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.username, uiHelper.language)}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password" className="fs-20 fw-900">
                  <Localization text="password" />
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={loginInfo.password}
                  onChange={e => inputHandler(e.target)}
                />
                {error && error.password !== '' ? (
                  <p
                    style={{
                      fontSize: '15px',
                      color: 'salmon',
                      fontWeight: '600',
                    }}
                  >
                    {translate(error.password, uiHelper.language)}
                  </p>
                ) : null}
              </div>
              <Link style={{ color: '#3bc9db' }} to="forgot-password">
                <Localization text="forgotYourPassword" />
              </Link>
            </div>

            {/* /Login Form */}

            {isLoginFailed ? (
              <p
                style={{
                  fontSize: '18px',
                  color: 'salmon',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                Login Failed
              </p>
            ) : null}
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                type="submit"
                className="btn btn-special radius-0 text-left next-btn fs-14 text-uppercase fw-900"
              >
                <Localization text="login" />
                <NextIcon color="#E9ECEF" className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withRouter(Login);
