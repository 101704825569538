import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// Components
import { BackIcon, ZoomIcon, ZoomoutIcon } from '../../../components/ui/icons';
import refreshTokenHandler from '../../../utils/generateToken';

const ActivityZoomableImage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = props;
  const uiHelper = useSelector(state=>state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  // const zoomoutHandler = () => {
  //   history.push(`/${data.previous_page_url.path}`);
  // };

  const nextHandler = () => {
    if (data.next_page_url === null) {
      axios
        .post(data.next_page_unlock_url, {}, {
          headers: {
            Authorization: 'Bearer ' + token
          }
         })
        .then(res => {
          history.push(`/${res.data.data.next_page_url.path}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
            refreshTokenHandler(dispatch, refreshToken)
          }
        });
    } else {
      history.push(`/${data.next_page_url.path}`);
    }
  };

  return (
    <div className="image-activity">
      <img src={data.title} alt="" className="pointer" onClick={nextHandler}/>
      
      {
        data.meta.options && (
          <>  
            {data.zoom
              ? (
                <button onClick={nextHandler} type="button" className="btn btn-dark radius-0 back-btn zoom-btn">
                  <ZoomIcon color="white"/>
                </button>
              )
              : (
                <button onClick={nextHandler} type="button" className="btn btn-dark radius-0 back-btn zoom-btn">
                  <ZoomoutIcon color="white"/>
                </button>
              )
            }
          </>
        )
      }
      
      {
        data.meta.options && (
          <button onClick={onPrevHandler} type="button" className="btn btn-dark radius-0 back-btn">
            <BackIcon color="#A5D8FF"/>
          </button>
        )}
    </div>
  );
};

export default ActivityZoomableImage;
