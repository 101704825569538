import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Promo from '../../containers/Promo';

import Localization from '../../utils/localization';

const HomeDesktop = () => {
  const token = useSelector(state => state.uiHelper.token);

  return (
    <>
      {token !== '' && <Redirect push to="/modules" />}

      {window.matchMedia('(display-mode: standalone)').matches ? (
        <div className="content-area">
          <div className="title">
            <img src="img/main.jpg" alt="" />
          </div>

          <div>
            <div className="content">
              <Link
                to="/login"
                className="btn btn-special btn-block text-uppercase fs-14 fw-900 text-left px-4"
              >
                <Localization text="login" />
                <img src="img/icons/next.svg" alt="" className="ml-3" />
              </Link>
              <Link
                to="/registration"
                className="btn btn-secondary btn-block text-uppercase fs-14 fw-900 text-left mt-4 px-4"
              >
                <Localization text="signup" />
                <img src="img/icons/next-black.svg" alt="" className="ml-3" />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Promo />
        </>
      )}
    </>
  );
};

export default HomeDesktop;
