import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Localization from '../../../utils/localization';
import { BackIcon, NextIcon } from '../../../components/ui/icons';

import {
  questionGroupByPageId,
  wrongQuestionByPageId,
} from '../../../utils/question';

const RetryWithWrongQuestion = props => {
  const history = useHistory();
  const { data, arabic } = props;
  const {
    title,
    content,
    questions,
    show_correct_answer,
    is_final_assessment,
  } = data;

  const retryHandler = () => {
    history.push(`/${data.assessment_first_page_url.path}`);
  };

  const nextHandler = () => {
    history.push(`/${data.certificate_page_url.path}`);
  };

  const onPrevHandler = () => {
    history.goBack();
  };

  return (
    <div className="content-area">
      <Title className={`bg-dark ${arabic}`}>
        {!is_final_assessment && (
          <p className="fs-17 text-secondary fw-700">
            <Localization text="module" /> {data.module_id}
          </p>
        )}
        <h4 className="fs-20 text-secondary-light fw-900">{title}</h4>
      </Title>

      <div>
        <div className="content">
          <div className="fs-23 fw-600">
            {content && <HtmlParser className="">{content}</HtmlParser>}
          </div>

          <div className={`wrong-questions mt-3 ${arabic}`}>
            {wrongQuestionByPageId(questions).map((q, i) => (
              <div key={i} className="wrong-questions__question fs-23 mt-2">
                <p className="text-primary fw-900 mt-4 mb-1">
                  <Localization text="question" />  {q.index}
                </p>
                {q.questions.map(({ title, correct_answers }) => (
                  <>
                    <p className="mb-3" style={{ color: '#212529' }}>
                      <HtmlParser className="">
                        {title.replace(
                          '##',
                          '<span style="background-color: #ffd43b;width: 82px; height: 12px;display: inline-block;"></span>'
                        )}
                      </HtmlParser>
                    </p>

                    {show_correct_answer && (
                      <div
                        style={{
                          font: 'normal normal 600 16px/22px Noto Sans',
                          letterSpacing: '0px',
                          color: '#212529',
                          opacity: 1,
                          marginTop: '-12px',
                          marginBottom: '16px',
                        }}
                      >
                        {correct_answers.map((answers, i) => (
                          <p key={i} className="py-1">
                            {answers}
                          </p>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className={`footer ${!show_correct_answer && 'failed-footer'}`}>
          <div className="d-flex justify-content-between">
            {show_correct_answer ? (
              <>
                <button
                  onClick={onPrevHandler}
                  type="button"
                  className="btn btn-dark radius-0 back-btn"
                >
                  <BackIcon color="#a5d8ff" />
                </button>

                <button
                  type="submit"
                  className="btn btn-special radius-0 text-left next-btn fs-14 text-uppercase fw-900"
                  onClick={nextHandler}
                >
                  <Localization text="next" />
                  <NextIcon color="#E9ECEF" className="ml-2" />
                </button>
              </>
            ) : (
              <button
                onClick={retryHandler}
                type="button"
                className="btn btn-primary radius-0 text-right next-btn"
              >
                <img src="/img/icons/retry.svg" alt="" className="mr-3" />
                <Localization text="retry" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetryWithWrongQuestion;
