import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Title from '../../../layout/Title';
import { DraggableAreasGroup } from 'react-draggable-tags';
import Localization from '../../../utils/localization';

const group = new DraggableAreasGroup();
const QuestionArea = group.addArea();
const OptionsArea = group.addArea();

const ActivityDragAndDrop = ({ data }) => {
  const history = useHistory();
  const { questions, option_sets } = data;
  const [optionTags, setOptionTags] = useState([]);

  // initialize 3 state for Questions
  const [firstAnswer, setFirstAnswer] = useState([]);
  const [secondAnswer, setSecondAnswer] = useState([]);
  const [thirdAnswer, setThirdAnswer] = useState([]);

  const backHanlder = () => {
    history.goBack();
  };

  const nextHandler = () => {
    let isPassed = true;
    [...firstAnswer, ...secondAnswer, ...thirdAnswer].forEach((answer, i) => {
      if (answer.id !== questions[i].id) {
        isPassed = false;
      }
    });

    if (isPassed) {
      history.push(`/${data.success_page_url.path}`);
    } else {
      history.push(`/${data.failure_page_url.path}`);
    }
  };

  const hasOptions = index => {
    if (index === 0) if (firstAnswer.length < 1) return false;
    if (index === 1) if (secondAnswer.length < 1) return false;
    if (index === 2) if (thirdAnswer.length < 1) return false;

    return true;
  };

  useEffect(() => {
    if (option_sets) {
      setOptionTags([...option_sets]);
      setFirstAnswer([]);
      setSecondAnswer([]);
      setThirdAnswer([]);
    }
  }, [option_sets]);

  return (
    <>
      {/* Our */}
      <div className="content-area">
        <Title>
          <ul className="drag-drop list-unstyled">
            {questions &&
              questions.map((question, i) => {
                let title = question.title.split('##');
                return (
                  <li key={i}>
                    {title[0]}
                    {hasOptions(i) ? null : (
                      <span className="show-question-positon"></span>
                    )}
                    <span className={hasOptions(i) ? 'drag-has-value' : ''}>
                      <QuestionArea
                        tags={
                          i === 0
                            ? firstAnswer
                            : i == 1
                            ? secondAnswer
                            : thirdAnswer
                        }
                        render={({ tag }) => tag.label}
                        onChange={tag => {
                          if (i == 0) {
                            setFirstAnswer(tag);
                          } else if (i == 1) {
                            setSecondAnswer(tag);
                          } else {
                            setThirdAnswer(tag);
                          }
                        }}
                        className=""
                      />
                    </span>{' '}
                    {title[1]}
                  </li>
                );
              })}
          </ul>
        </Title>

        <div>
          <div className="content" style={{ overflow: 'initial' }}>
            <div className="drag-options">
              <OptionsArea
                tags={optionTags}
                render={({ tag }) => <span>{tag.label}</span>}
                onChange={tags => {
                  setOptionTags(tags);
                }}
              />
            </div>
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={backHanlder}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={nextHandler}
                className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
                disabled={
                  firstAnswer.length === 0 ||
                  secondAnswer.length === 0 ||
                  thirdAnswer.length === 0
                }
              >
                <Localization text="next" />
                <NextIcon color="#495057" className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityDragAndDrop;
