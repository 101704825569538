import { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';

const VerifyEmail = () => {
  const history = useHistory();
  useEffect(() => {
    const { token, username } = queryString.parse(window.location.search);
    if (token && username) {
      axios
        .post(process.env.REACT_APP_API_URL + '/verify-email', {
          username,
          token,
        })
        .then(res => {
          if (res.status === 200) {
            history.push(
              `/login?${queryString.stringify({
                redirectMsg:
                  'Your email has been verified successfully! Please login to start the course',
              })}`
            );
          } else {
            history.push(
              `/login?${queryString.stringify({
                redirectMsg: 'Email verification failed! Please try again...',
                isRedirectErrorMsg: true,
              })}`
            );
          }
        })
        .catch(err => {
          history.push(
            `/login?${queryString.stringify({
              redirectMsg: 'Email verification failed! Please try again...',
              isRedirectErrorMsg: true,
            })}`
          );
        });
    } else {
      history.push('/login');
    }
  }, []);

  return <div className="verify-email-loader"></div>;
};

export default withRouter(VerifyEmail);
