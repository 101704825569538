const content = {
  select: {
    en: '---Select---',
    ar: '---أختر---',
  },
  male: {
    en: 'Male',
    ar: 'ذكر',
  },
  female: {
    en: 'Female',
    ar: 'أنثى',
  },
  other: {
    en: 'Other',
    ar: 'أخرى',
  },
  notSay: {
    en: "I'd rather not say",
    ar: 'أفضل عدم القول',
  },
  under35: {
    en: '35 and Under',
    ar: '35 وما دون',
  },
  over35: {
    en: 'Older than 35',
    ar: 'أكبر من 35',
  },
  errorTncAndPP: {
    en:
      'Please tick the boxes to agree to the terms & conditions and privacy policy.',
    ar:
      'يرجى التأشير على المربعات للموافقة على البنود والشروط وسياسة الخصوصية.',
  },
  errorEmptyUserName: {
    en: 'Please enter a username.',
    ar: 'الرجاء إدخال اسم المستخدم.',
  },
  errorEmptyPassword: {
    en: 'Please enter a password.',
    ar: 'Please enter a password. (arabic)',
  },
  errorCreatePassword: {
    en: 'Please create a password.',
    ar: 'يرجى إنشاء كلمة مرور.',
  },
  errorCreateConfirmPassword: {
    en: 'confirm Please create a password.',
    ar: 'confirm Please create a password. (arabic)',
  },
  errorPasswordLength: {
    en: 'Password must be at least 6 characters.',
    ar: 'يجب أن تكون كلمة المرور 6 أحرف على الأقل.',
  },
  errorEmptyConfirmPassword: {
    en: 'Please confirm your password.',
    ar: 'يرجى تأكيد كلمة المرور.',
  },
  errorPasswordMustMatch: {
    en: 'Password must match.',
    ar: 'كلمة المرور يجب ان تتطابق.',
  },
  errorEmptyEmail: {
    en: 'Please enter your email address.',
    ar: 'يرجى إدخال عنوان بريدك الإلكتروني.',
  },
  errorInvalidEmail: {
    en: 'Your email address is invalid!',
    ar: 'عنوان البريد الإلكتروني الخاص بك غير صالحة!',
  },
  errorEmptyCountry: {
    en: 'Please select a country.',
    ar: 'يرجى اختيار البلد.',
  },
  errorEmptyPortNumber: {
    en: 'Please select a port.',
    ar: 'يرجى اختيار الميناء.',
  },
  errorEmptyUnion: {
    en: 'Please select a union.',
    ar: 'يرجى اختيار اتحاد.',
  },
  errorEmptyAge: {
    en: 'Please select an age range.',
    ar: 'الرجاء تحديد الفئة العمرية.',
  },
  errorEmptyGender: {
    en: 'Please select a gender.',
    ar: 'Please select a gender. (arabic)',
  },
  errorEmptyOption: {
    en: 'Please select an option.',
    ar: 'ارجوك اختر خيار واحد.',
  },
};

const translate = (key, lang) => {
  return lang === 'ar' ? content[key].ar : content[key].en;
};

export default translate;
