import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackIcon, NextIcon } from '../../components/ui/icons';
import Title from '../../layout/Title';
import { DraggableAreasGroup } from 'react-draggable-tags';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Localization from '../../utils/localization';
import refreshTokenHandler from '../../utils/generateToken';

const group = new DraggableAreasGroup();
const QuestionArea = group.addArea();
const OptionsArea = group.addArea();

const ActivityDragAndDrop = ({ data, isCorrectAnswer, isPassed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { questions, option_sets } = data;
  const [optionTags, setOptionTags] = useState([]);

  // initialize 3 state for Questions
  const [firstAnswer, setFirstAnswer] = useState([]);
  const [secondAnswer, setSecondAnswer] = useState([]);
  const [thirdAnswer, setThirdAnswer] = useState([]);

  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;

  const backHanlder = () => {
    history.goBack();
  };

  const nextHandler = () => {
    let isTrue = true;
    [...firstAnswer, ...secondAnswer, ...thirdAnswer].forEach((answer, i) => {
      if (answer.id !== questions[i].id) {
        isTrue = false;
      }
    });

    const options = {
      module_id: data.module_id,
      section_id: data.section_id,
      page_id: data.id,
      answers: [...firstAnswer, ...secondAnswer, ...thirdAnswer].map(
        (answer, i) => {
          return {
            question_id: questions[i].id,
            is_correct: answer.id === questions[i].id ? 1 : 0,
          };
        }
      ),
    };

    if (isTrue) {
      // history.push(`/${data.success_page_url.path}`);
      isCorrectAnswer(true, true);
    } else {
      isCorrectAnswer(true, false);
      // history.push(`/${data.failure_page_url.path}`);
    }

    axios
      .post(
        process.env.REACT_APP_API_URL + '/final-assessment-answers',
        options,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Accept-Language': language ? language : 'en',
          },
        }
      )
      .then(res => {
        if (data.is_assesment_last_page) {
          axios
            .post(
              data.final_assessment_submit_url,
              {
                module_id: data.module_id,
                section_id: data.section_id,
                page_id: data.id,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              }
            )
            .then(res => {
              history.push(`/${res.data.data.next_page_url.path}`);
            })
            .catch(err => {
              if (err.response.status === 401) {
                refreshTokenHandler(dispatch, refreshToken);
              }
            });
        } else {
          if (data.next_page_url === null) {
            axios
              .post(
                data.next_page_unlock_url,
                {},
                {
                  headers: {
                    Authorization: 'Bearer ' + token,
                  },
                }
              )
              .then(res => {
                history.push(`/${res.data.data.next_page_url.path}`);
              })
              .catch(err => {
                if (err.response.status === 401) {
                  refreshTokenHandler(dispatch, refreshToken);
                }
              });
          } else {
            history.push(`/${data.next_page_url.path}`);
          }
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          refreshTokenHandler(dispatch, refreshToken);
        }
      });
  };

  const hasOptions = index => {
    if (index === 0) if (firstAnswer.length < 1) return false;
    if (index === 1) if (secondAnswer.length < 1) return false;
    if (index === 2) if (thirdAnswer.length < 1) return false;

    return true;
  };

  useEffect(() => {
    if (option_sets) {
      setOptionTags([...option_sets]);
      setFirstAnswer([]);
      setSecondAnswer([]);
      setThirdAnswer([]);
    }
  }, [option_sets]);

  return (
    <>
      <div className="content-area">
        <Title>
          <ul className="drag-drop list-unstyled">
            {questions &&
              questions.map((question, i) => {
                let title = question.title.split('##');
                return (
                  <li key={i}>
                    {title[0]}
                    {hasOptions(i) ? null : (
                      <span className="show-question-positon"></span>
                    )}
                    <span className={hasOptions(i) ? 'drag-has-value' : ''}>
                      <QuestionArea
                        tags={
                          i === 0
                            ? firstAnswer
                            : i === 1
                            ? secondAnswer
                            : thirdAnswer
                        }
                        render={({ tag }) => tag.label}
                        onChange={tag => {
                          if (i === 0) {
                            setFirstAnswer(tag);
                          } else if (i === 1) {
                            setSecondAnswer(tag);
                          } else {
                            setThirdAnswer(tag);
                          }
                        }}
                        className=""
                      />
                    </span>{' '}
                    {title[1]}
                  </li>
                );
              })}
          </ul>
        </Title>

        <div>
          <div className="content" style={{ overflow: 'initial' }}>
            <div className="drag-options">
              <OptionsArea
                tags={optionTags}
                render={({ tag }) => <span>{tag.label}</span>}
                onChange={tags => {
                  setOptionTags(tags);
                }}
              />
            </div>
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={backHanlder}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={nextHandler}
                className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
                disabled={
                  firstAnswer.length === 0 ||
                  secondAnswer.length === 0 ||
                  thirdAnswer.length === 0
                }
              >
                <Localization text="next" />
                <NextIcon color="#495057" className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityDragAndDrop;
