import { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Input, Modal, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import { BackIcon, NextIcon, InfoIcon } from '../../../components/ui/icons';
import { surveyAnswerFormat } from '../../../utils/utils';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';

const ActivityCarousal = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, arabic } = props;
  const { questions } = data;
  const [modal, setModal] = useState(false);
  const [question, setQuestion] = useState({});
  const [position, setPosition] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [qid, setQid] = useState([]);
  const uiHelper = useSelector(state => state.uiHelper);
  const { token, refreshToken } = uiHelper;

  const toggle = () => setModal(!modal);

  const onChangeHandler = value => {
    let oldAnswers = answers;
    oldAnswers[position] = [value];
    setAnswers([...oldAnswers]);
  };

  const onPrevHandler = () => {
    if (position !== 0) {
      setPosition(position - 1);
    } else {
      history.goBack();
    }
  };

  const nextHandler = () => {
    if (position !== questions.length - 1) {
      setPosition(position + 1);
    } else {
      const formatedAnswer = surveyAnswerFormat(answers, qid, data.module_id);
      if (formatedAnswer.submitted_questions.length > 0) {
        axios
          .post(data.survey_submit_url, formatedAnswer, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then(res => {
            if (res.status === 201) {
              // console.log('data submitted successfully');
            }

            // push to next page
            if (data.next_page_url === null) {
              axios
                .post(
                  data.next_page_unlock_url,
                  {},
                  {
                    headers: {
                      Authorization: 'Bearer ' + token,
                    },
                  }
                )
                .then(res => {
                  history.push(`/${res.data.data.next_page_url.path}`);
                })
                .catch(err => {
                  if (err.response.status === 401) {
                    refreshTokenHandler(dispatch, refreshToken);
                  }
                });
            } else {
              history.push(`/${data.next_page_url.path}`);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              refreshTokenHandler(dispatch, refreshToken);
            }
            // push to next page
            if (data.next_page_url === null) {
              axios
                .post(
                  data.next_page_unlock_url,
                  {},
                  {
                    headers: {
                      Authorization: 'Bearer ' + token,
                    },
                  }
                )
                .then(res => {
                  history.push(`/${res.data.data.next_page_url.path}`);
                })
                .catch(err => {
                  if (err.response.status === 401) {
                    refreshTokenHandler(dispatch, refreshToken);
                  }
                });
            } else {
              history.push(`/${data.next_page_url.path}`);
            }
          });
      } else {
        if (data.next_page_url === null) {
          axios
            .post(
              data.next_page_unlock_url,
              {},
              {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              }
            )
            .then(res => {
              history.push(`/${res.data.data.next_page_url.path}`);
            })
            .catch(err => {
              if (err.response.status === 401) {
                refreshTokenHandler(dispatch, refreshToken);
              }
            });
        } else {
          history.push(`/${data.next_page_url.path}`);
        }
      }
    }
  };

  useEffect(() => {
    if (questions) {
      setQuestion({
        ...questions[position],
      });

      let qid = questions.map(question => question.id);
      setQid([...qid]);
    }
  }, [questions, position]);

  return (
    question && (
      <div className="content-area">
        <Title className="position-relative">
          {question.meta ? (
            <img
              src={JSON.parse(question.meta).image}
              alt=""
              className="h-100"
            />
          ) : null}

          <div className="home-icon info-icon pointer" onClick={toggle}>
            <InfoIcon color="#212529" />
          </div>
        </Title>

        <div>
          <div className={`content ${arabic}`}>
            <div>
              <h4
                style={{
                  fontWeight: '600',
                  fontSize: '25px',
                  marginBottom: '0',
                }}
              >
                {question.mega_title}
              </h4>
              <HtmlParser>{question.title}</HtmlParser>
            </div>

            <div
              className="row"
              style={{
                marginLeft: 0,
              }}
            >
              {question.options &&
                Object.entries(question.options).map((option, i) => (
                  <div
                    className="col-4"
                    style={{
                      paddingRight: 0,
                    }}
                    key={i}
                  >
                    <Label check>
                      <Input
                        value={option[0]}
                        onChange={() => onChangeHandler(option[0])}
                        type="radio"
                        name={`radio-${question.id}`}
                        checked={
                          answers[position] &&
                          answers[position][0] === option[0]
                        }
                      />{' '}
                      {option[1]}
                    </Label>
                  </div>
                ))}
            </div>
          </div>

          <div className="footer">
            <div className="d-flex justify-content-between">
              <button
                onClick={onPrevHandler}
                type="button"
                className="btn btn-dark radius-0 back-btn"
              >
                <BackIcon color="#a5d8ff" />
              </button>

              <button
                onClick={nextHandler}
                className={`btn btn-${
                  data && data.type === 13 ? 'primary' : 'secondary'
                } radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
              >
                <Localization text="next" />
                <NextIcon color="#495057" className="ml-2" />
              </button>
            </div>
          </div>
        </div>

        <Modal isOpen={modal} className="modal-dialog-centered" toggle={toggle}>
          <HtmlParser className={`p-4 ${arabic}`}>{question.info}</HtmlParser>
        </Modal>
      </div>
    )
  );
};

export default ActivityCarousal;
