import { useSelector } from 'react-redux';

const translate = {
  login: {
    en: 'دخول',
    ar: 'Login',
  },
  logout: {
    en: 'Logout',
    ar: 'خروج',
  },
  signup: {
    en: 'Sign up',
    es: 'Registrarme',
    pt: 'Inscrever',
    ar: 'تسجيل الدخول',
  },
  registration: {
    en: 'Registration',
    ar: 'التسجيل',
  },
  beforeYouBegin: {
    en: 'Before you begin',
    ar: 'قبل أن تبدأ',
  },
  fillInTheFormText: {
    en: 'Fill in the form below to register your new account.',
    ar: 'املأ النموذج أدناه لتسجيل حسابك الجديد.',
  },
  requiredInfo: {
    en: '*Required information',
    ar: '*معلومات مطلوبة',
  },
  userName: {
    en: 'Username',
    ar: 'اسم المستخدم',
  },
  password: {
    en: 'Password',
    ar: 'كلمة المرور',
  },
  confirmPassword: {
    en: 'Confirm password',
    ar: 'تأكيد كلمة المرور',
  },
  email: {
    en: 'Email',
    ar: 'البريد الإلكتروني',
  },
  country: {
    en: 'Country',
    ar: 'الدولة',
  },
  gender: {
    en: 'Gender',
    ar: 'جنس تذكير أو تأنيث',
  },
  age: {
    en: 'Age',
    ar: 'سن',
  },
  portName: {
    en: 'Port name',
    ar: 'اسم الميناء',
  },
  unionName: {
    en: 'Union name',
    ar: 'اسم النقابة',
  },
  select: {
    en: 'Select',
    ar: 'اختار',
  },
  changeLanguage: {
    en: 'Change language',
    ar: 'تغيير اللغة',
  },
  english: {
    en: 'English',
    ar: 'الإنجليزية',
  },
  arabic: {
    en: 'Arabic',
    ar: 'العربية',
  },
  termsAndCodition: {
    en: 'Terms & Conditions',
    es: 'Términos y condiciones',
    pt: 'Termo e Condições',
    ar: 'الأحكام والشروط',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    es: 'Política de privacidad',
    pt: 'Política de Privacidade',
    ar: 'سياسة الخصوصية',
  },
  iAgreeToThe: {
    en: 'I agree to the',
    ar: 'أوافق على',
  },
  aboutTheAuthors: {
    en: 'About the authors',
    es: 'Acerca de los autores',
    pt: 'Sobre os autores',
    ar: 'نبذة عن المؤلفين',
  },
  ItfSefetyAndHealth: {
    en: 'ITF Occupational Safety and Health Training',
    ar: 'التدريب في مجال السلامة والصحة المهنية في الـITF',
  },
  forgotYourPassword: {
    en: 'Forgot your password?',
    ar: 'هل نسيت كلمة المرور؟',
  },
  successForgotMsg: {
    en:
      'Thank you. A link to reset your password has been sent to the email address you provided.',
    ar:
      'شكرًا لك. تم إرسال رابط لإعادة تعيين كلمة المرور إلى عنوان البريد الإلكتروني الذي قمت بتزويده.',
  },
  successSignInMsg: {
    en: 'Welcome back, ',
    ar: 'أهلاً بك, ',
  },
  question: {
    en: 'Question',
    ar: 'السؤال',
  },
  congrats: {
    en: 'Congratulations!',
    ar: 'تهانينا!',
  },
  home: {
    en: 'Home',
    ar: 'الصفحة الرئيسية',
  },
  next: {
    en: 'next',
    ar: 'التالي',
  },
  login: {
    en: 'Log In',
    es: 'Iniciar sesión',
    pt: 'Entrar',
    ar: 'دخول',
  },
  forgot: {
    en: 'Forgot Password',
    ar: 'Forgot Password',
  },
  logout: {
    en: 'Logout',
    ar: 'تسجيل خروج',
  },
  start: {
    en: 'Start',
    ar: 'ابدأ',
  },
  module: {
    en: 'Module',
    ar: 'الوحدة',
  },
  modules: {
    en: 'Modules',
    ar: 'الوحدات',
  },
  introPage: {
    en: 'Introduction to OSH Ports',
    ar: 'مقدمة عن ميناء آمن',
  },
  changeLanguage: {
    en: 'Change language',
    ar: 'تغيير اللغة',
  },
  of: {
    en: 'of',
    ar: 'من',
  },
  duration: {
    en: 'Duration',
    ar: 'المدة',
  },
  hours: {
    en: 'hours',
    ar: 'ساعات',
  },
  overview: {
    en: 'Overview',
    ar: 'نظرة عامة',
  },
  contents: {
    en: 'Contents',
    es: '',
    pt: '',
    ar: 'جدول المحتويات',
  },
  resources: {
    en: 'Resources',
    ar: 'المصادر',
  },
  section: {
    en: 'Section',
    ar: 'الجزء',
  },
  activity: {
    en: 'Activity',
    ar: 'النشاط',
  },
  finalAssessment: {
    en: 'Final assessment',
    ar: 'التقييم النهائي',
  },
  Assessment: {
    en: 'Assessment',
    ar: 'التقييم',
  },
  welcomeText: {
    en: 'Welcome to this online training programme!',
    ar: 'أهلاً بكم في هذا البرنامج التدريبي عبر الإنترنت',
  },
  introduction: {
    en: 'Introduction to OSH Ports',
    ar: 'مقدمة عن ميناء آمن',
  },
  resumeLearning: {
    en: 'Resume learning',
    ar: 'استئناف التعلم',
  },
  covidTitle: {
    en: 'ITF Dockers Coronavirus Universal Work Site Protocols',
    ar:
      'بروتوكولات مواقع العمل العالمية الصادرة عن الاتحاد الدولي لعمال النقل (ITF) لعمال الرصيف بخصوص تفشي فيروس كورونا',
  },
  retry: {
    en: 'Retry',
    ar: 'إعادة المحاولة',
  },
  promoWelcomeToOSHPorts: {
    en: 'Welcome to your new occupational safety and health course: OSH Ports',
    es:
      'Bienvenido/a a tu nuevo curso sobre la seguridad y salud en el trabajo: SST en los Puertos',
    pt:
      'Bem-vindo a seu novo curso sobre segurança e saúde ocupacional: Segurança e Saúde Ocupacional nos Portos',
    ar:
      'مرحبًا بك في الدورة التدريبية الجديدة حول الصحة و السلامة المهنية: السلامة والصحة المهنية في الموانئ',
  },
  promoOSHPortsDesc1: {
    en: `Funded by the International Transport Workers' Federation Seafarers’ Trust (UK) and supported by the International Training Centre of the International Labour Organization (Turin, Italy), OSH Ports will reinforce your knowledge of the basics of port safety and health, and help with the worldwide effort to curb the unacceptably high numbers of port injuries and fatalities.`,
    es:
      'Financiado por el Fondo de Marinos de la Federación Internacional de Trabajadores del Transporte (Reino Unido) y apoyado por el Centro Internacional de Formación de la Organización Internacional de Trabajo (Turín, Italia), SST en los Puertos reforzará tus conocimientos sobre los conceptos fundamentales de la seguridad y salud en los puertos y ayudará con el esfuerzo mundial para frenar el número inaceptablemente elevado de lesiones y fatalidades en los puertos.',
    pt:
      'Financiado pelo Fundo de bem-estar da Gente de Mar da Federação Internacional dos Trabalhadores em Transportes (Reino Unido) e apoiada pelo Centro Internacional de Treinamento da Organização Internacional do Trabalho (Turim, Itália), SSO nos Portos reforçará seus conhecimentos básicos sobre segurança e saúde nos portos e vai cooperar com o esforço mundial para conter o número inaceitavelmente alto de lesões e fatalidades portuárias.',
    ar: `بدعم من صندوق بحارة الاتحاد الدولي لعمال النقل (المملكة المتحدة) وبدعم من مركز التدريب الدولي التابع لمنظمة العمل الدولية (تورينو، إيطاليا)، سوف يعمل برنامج موانئ الصحة والسلامة المهنية OSH على تعزيز معرفتك بأساسيات السلامة والصحة في الموانئ، ويساعد في الجهود العالمية للحد من أعداد الإصابات والوفيات المرتفعة بشكل غير مقبول في الموانئ.`,
  },
  promoOSHPortsDesc2: {
    en: `This course has been designed to be taken in just a few hours, and is packed full of important information, links and templates to start making your immediate working environment safer for you and your workmates right now.`,
    es:
      'Este curso ha sido diseñado para tomarse en tan solo unas pocas horas y está repleto de información importante, enlaces y plantillas para que puedas comenzar el trabajo de conseguir que tu entorno inmediato de trabajo sea más seguro para ti y para tus compañeros a partir de ahora mismo.',
    pt:
      'Este curso foi projetado para ser feito em apenas algumas horas e está cheio de informações, links e modelos importantes para começar a tornar seu ambiente de trabalho imediato mais seguro para você e seus colegas de trabalho a partir deste momento.',
    ar: `لقد تم تصميم هذه الدورة التدريبية بحيث يتم أخذها في غضون بضع ساعات فقط، وهي مليئة بالمعلومات المهمة والروابط والنماذج اللازمة لكي تبدأ فوراً في جعل بيئة عملك أكثر أماناً لك ولزملائك في العمل.`,
  },
  promoOSHPortsDescLI1: {
    en: '5 modules covering the fundamentals of port safety and health',
    es:
      '5 módulos que abarcan los conceptos fundamentales sobre la seguridad y salud en los puertos',
    pt:
      '5 módulos que abarcan los conceptos fundamentales sobre la seguridad y salud en los puertos',
    ar: 'وحدات تغطي أساسيات السلامة والصحة في الموانئ 5',
  },
  promoOSHPortsDescLI2: {
    en: 'Section on working safely amid COVID-19',
    es: 'Sección sobre trabajar de forma segura en medio de COVID-19',
    pt:
      'Inclui uma seção sobre o trabalho com segurança em meio à pandemia de Covid-19',
    ar: ' قسم خاص للعمل بأمان وسط وباء كوفيد-19',
  },
  promoOSHPortsDescLI3: {
    en: 'Take the course in English or Arabic',
    es: '',
    pt: '',
    ar: 'تستطيع أخذ الدورة التدريبية باللغة الإنجليزية أو العربية',
  },
  promoOSHPortsDescLI4: {
    en: 'Receive an industry-supported certificate of completion',
    es: 'Recibir un certificado de finalización endosado por la industria',
    pt: 'Receba um certificado de conclusão reconhecido pelo setor',
    ar: 'تحصل على شهادة إكمال الدورة المدعومة من الصناعة',
  },
  downloadOSH: {
    en: 'Download OSH Ports',
    es: 'Descarga SST en los Puertos',
    pt: 'Baixar SSO nos Portos',
    ar: 'قم بتنزيل موانئ OSH Ports',
  },
  availableApps: {
    en: 'Download it from the Google Play Store',
    es: '',
    pt: '',
    ar: 'قم بتنزيله من متجر جوجل Google Play',
  },
  appsLogoLLC: {
    en: 'Google Play and the Google Play logo are trademarks of Google LLC.',
    es:
      'Google Play y el logotipo de Google Play son marcas comerciales de Google LLC.',
    pt:
      'Google Play e o logotipo do Google Play são marcas registradas da Google LLC.',
    ar:
      ' Google Play وشعار Google Play هما علامتان تجاريتان لشركة Google Play.',
  },

  appleAppsLogoLLC: {
    en: 'Apple logo® and App Store® are trademarks of Apple Inc.',
    es: 'Apple logo® y App Store® son marcas comerciales de Apple Inc.',
    pt: 'Apple logo® e App Store® são marcas comerciais da Apple Inc.',
    ar: 'Apple logo® و App Store® علامتان تجاريتان لشركة Apple Inc.',
  },

  singInTitle: {
    en: 'Sign in to your OSH Ports account',
    es: 'Inicia sesión en tu cuenta de SST en los Puertos',
    pt: 'Inicie sessão na sua conta de SSO nos Portos',
    ar: 'تسجيل الدخول إلى حساب السلامة والصحة المهنية في الموانئ الخاص بك',
  },
  signDesc1: {
    en:
      'Register for your new account below to start using OSH Ports right away!',
    es:
      '¡Regístrate en tu nueva cuenta a seguir para empezar a usar SST en los Puertos de inmediato!',
    pt:
      'Inscreva-se na sua nova conta abaixo para começar a usar SSO nos Portos imediatamente!',
    ar:
      'سجل للحصول على حسابك الجديد أدناه لبدء استخدام تطبيق OSH Ports على الفور!',
  },
  signDesc2: {
    en:
      'For those of you who have already registered, welcome back! Enter your username and password to sign in.',
    es:
      'Si ya te has registrado en la nueva versión de la aplicación, ¡bienvenido! Introduce tu nombre de usuario y contraseña para iniciar sesión.',
    pt:
      'Se você já se inscreveu na nova versão do app, seja bem-vindo! Digite seu nome de usuário e senha para fazer login.',
    ar:
      'لقد قمت بالفعل بالتسجيل في الإصدار الجديد من التطبيق، مرح ًبا بك مرة أخرى! أدخل اسم المستخدم وكلمة المرور لتسجيل الدخول.',
  },

  attensionForArab: {
    en: (
      <>
        <span>Attention, Arab world users:</span> You can still log in to your
        existing OSH Ports course via the link below up until 27/07/2023. Thank
        you:
      </>
    ),
    es: (
      <>
        <span>Atención, usuarios del mundo árabe:</span> Pueden acceder a su
        curso existente de SST en los Puertos usando el enlace a seguir hasta la
        fecha 27/07/2023. ¡Gracias!:
      </>
    ),
    pt: (
      <>
        <span>Atenção usuários do mundo árabe:</span> Você pode acessar seu
        curso existente de SSO nos Portos usando o link a seguir até a data
        27/07/2023. Obrigado!
      </>
    ),
    ar: (
      <>
        <span>يرجى الانتباه، المستخدمين من العالم العربي:</span> يمكنك تسجيل
        الدخول إلى دورة موانئ الصحة والسلامة الحالية الخاصة بك عبر الرابط حتى
        27/07/2023. شكراً لكم.
      </>
    ),
  },

  loginTitle: {
    en:
      'If you’re already registered, welcome back! Enter your ID and password below to pick up where you left off.',
    es: '',
    pt: '',
    ar:
      'إذا كنت مسجلاً بالفعل، فمرحبًا بك مرة أخرى! أدخل هويتك وكلمة المرور أدناه لتعرف أين توقفت.',
  },
  signupTitle: {
    en:
      'Follow the instructions below to register your new account. You’ll be able to start using OSH Ports right away.',
    es: '',
    pt: '',
    ar:
      'اتبع الإرشادات أدناه لتسجيل حسابك الجديد. سوف تتمكن من بدء استخدام موانئ OSH على الفور.',
  },
  promoQuotes: {
    en: `“It’s our workplace. It’s your workplace. It’s our collective workplace. Let’s make it safe. Let’s use the app.”`,
    es: `“Es nuestro lugar de trabajo. Es tu lugar de trabajo. Es nuestro lugar de trabajo colectivo. Hagámoslo seguro. Usemos la aplicación.”`,
    pt: `“É o nosso local de trabalho. É seu local de trabalho. È o nosso local de trabalho coletivo. Vamos torná-lo seguro. Vamos utilizar o aplicativo.”`,
    ar: `"إنه مكان عملنا. إنه مكان عملك. إنه مكان عملنا الجماعي. دعونا نجعله آمناً. دعونا نستخدم التطبيق."`,
  },
  tutorials_osh: {
    en:
      'Click on the video below to watch a quick tutorial on how to use OSH Ports.',
    es:
      'Haz clic en el vídeo a continuación para ver un breve tutorial sobre cómo utilizar SST en los Puertos.',
    pt:
      'Clicar no vídeo abaixo para ver um rápido tutorial sobre o uso do curso SSO nos Portos.',
    ar:
      'انقر فوق الفيديو أدناه لمشاهدة برنامج تعليمي سريع حول كيفية استخدام تطبيق الصحة و السلامة المهنية. يمكنك مشاهدة الفيديو باللغتين العربية والإنجليزية',
  },
  promoQuoteOwner: {
    en: `-Paddy Crumlin`,
    es: `-Paddy Crumlin`,
    pt: `-Paddy Crumlin`,
    ar: `بادي كروملين`,
  },
  promoQuoteOwnerDesignation: {
    en: `President, ITF`,
    es: 'Presidente, ITF',
    pt: 'Presidente, ITF',
    ar: `رئيس، الـITF`,
  },
  promoQuoteOwnerOrganization: {
    en: `Chair, Dockers’ Section`,
    es: 'ITF Presidente de la Sección Portuaria',
    pt: 'Presidente, Seção de Trabalhadores Portuários',
    ar: `رئيس قسم عمال الرصيف`,
  },
  partners: {
    en: `Partners`,
    es: 'Socios',
    pt: 'Sócios',
    ar: `الشركاء`,
  },
  usingOSHPorts: {
    en: 'Using OSH Ports',
    ar: 'استخدام موانئ الصحة والسلامة المهنية OSH',
  },
  contactITF: {
    en: 'For information, contact ITF-ST at:',
    es: 'Para obtener información, comuníquese con ITF-ST en:',
    pt: 'Para obter informações, entre em contato com a ITF-ST em:',
    ar: 'للحصول على المعلومات، اتصل بـ ITF-ST على:',
  },
 contactITFSecondRow: {
    en: 'For technical issues, please contact:',
    es: 'Para problemas técnicos, comuníquese con:',
    pt: 'Para questões técnicas, entre em contato com:',
    ar: 'للمشاكل التقنية، يرجى الاتصال بـ:',
  },
  name: {
    en: 'Name',
    ar: 'الاسم',
  },
  receiveCertificate: {
    en: 'RECEIVE CERTIFICATE',
    ar: 'استلام الشهادة',
  },
  returnToHomePage: {
    en: 'Return to homepage',
    ar: 'العودة للصفحة الرئيسية',
  },
  takeMeToTheNewApp: {
    en: 'Take me to the new app.',
    es: 'Llévame a la nueva aplicación.',
    pt: 'Leve-me para o novo aplicativo.',
    ar: 'خذني إلى التطبيق الجديد.',
  },
  moodleLinkTitle: {
    en: 'This link takes you to the Moodle login.',
    es: 'Este enlace lo lleva al inicio de sesión de Moodle.',
    pt: 'Este link leva você ao login do Moodle.',
    ar: 'هذا الرابط يأخذك إلى تسجيل الدخول إلى موودل',
  },
};

const Localization = ({ text }) => {
  const lang = useSelector(state => state.uiHelper.language);

  return translate[text][lang] || translate[text]?.en || '';

  // return lang === 'ar' ? translate[text].ar : translate[text].en;
  // switch (lang) {
  //   case 'ar':
  //     return translate[text]?.ar || translate[text]?.en;
  //   case 'pt':
  //     return translate[text]?.pt || translate[text]?.en;
  //   case 'es':
  //     return translate[text]?.es || translate[text]?.en;
  //   default:
  //     return translate[text]?.en;
  // }
};

export default Localization;
