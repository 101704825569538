import { FormGroup, Label, Input } from 'reactstrap';
import HtmlParser from '../../utils/htmlParser';

const Radio = props => {
  const { question, getAnswer, prevAnswer, className } = props;

  const onChangeHandler = value => {
    getAnswer(value);
  };

  return (
    <div>
      <HtmlParser className={className}>{question.title}</HtmlParser>

      {question.genre && (
        <>
          <br />
          <span
            style={{
              fontWeight: 'bold',
              marginBottom: '3px',
              display: 'block',
            }}
          >
            {question.genre}
          </span>
        </>
      )}

      {Object.entries(question.options !== null && question.options).map(
        (option, i) => {
          return (
            <FormGroup className={`${className} mt-1`} key={i} check>
              <Label check>
                <Input
                  value={option[0]}
                  onChange={() => onChangeHandler(option[0])}
                  type="radio"
                  name={`radio-${question.id}`}
                  checked={prevAnswer === option[0]}
                />{' '}
                {option[1]}
              </Label>
            </FormGroup>
          );
        }
      )}
    </div>
  );
};

export default Radio;
