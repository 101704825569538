import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { BackIcon, NextIcon } from '../../../components/ui/icons';
import Title from '../../../layout/Title';
import HtmlParser from '../../../utils/htmlParser';
import Questions from '../../../components/questions/Questions';
import DnDQuestion from '../../../components/questions/DnDQuestion';
import Localization from '../../../utils/localization';
import refreshTokenHandler from '../../../utils/generateToken';
import { getAnswerSet } from '../../../utils/question';

const FinalAssessment = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data,
    prevAnswersHandler,
    isCorrectAnswer,
    prevAnswers,
    isPassed,
    arabic,
    getCollectAnswer,
  } = props;

  const {
    module_id,
    module_position,
    title,
    is_final_assessment,
    content,
    questions,
    is_drag_n_drop,
    question_index,
  } = data;

  const [isGotoNext, setIsGotoNext] = useState(false);
  const uiHelper = useSelector(state => state.uiHelper);
  const { language, token, refreshToken } = uiHelper;

  const onPrevHandler = () => {
    history.goBack();
  };

  const nextHandler = () => {
    let submittedAnswer = prevAnswers[question_index - 1];

    const options = {
      module_id: data.module_id,
      section_id: data.section_id,
      page_id: data.id,
      answers: getAnswerSet(questions, submittedAnswer),
    };

    console.log('-------stop--------');

    axios
      .post(
        process.env.REACT_APP_API_URL + '/final-assessment-answers',
        options,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Accept-Language': language ? language : 'en',
          },
        }
      )
      .then(res => {
        if (data.is_assesment_last_page) {
          let totalCorrectAnswer = getCollectAnswer();
          axios
            .post(
              data.final_assessment_submit_url,
              {
                module_id: data.module_id,
                section_id: data.section_id,
                page_id: data.id,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              }
            )
            .then(res => {
              history.push(`/${res.data.data.next_page_url.path}`);
            })
            .catch(err => {
              if (err.response.status === 401) {
                refreshTokenHandler(dispatch, refreshToken);
              }
            });
        } else {
          if (data.next_page_url === null) {
            axios
              .post(
                data.next_page_unlock_url,
                {},
                {
                  headers: {
                    Authorization: 'Bearer ' + token,
                  },
                }
              )
              .then(res => {
                history.push(`/${res.data.data.next_page_url.path}`);
              })
              .catch(err => {
                if (err.response.status === 401) {
                  refreshTokenHandler(dispatch, refreshToken);
                }
              });
          } else {
            history.push(`/${data.next_page_url.path}`);
          }
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          refreshTokenHandler(dispatch, refreshToken);
        }
      });
  };

  return (
    <>
      {is_drag_n_drop ? (
        <DnDQuestion
          data={data}
          isCorrectAnswer={(submittedAnswers, currectAnswers) =>
            isCorrectAnswer(
              submittedAnswers,
              currectAnswers,
              data.question_index
            )
          }
          isPassed={isPassed}
        />
      ) : (
        <div className="content-area">
          <Title className={`bg-dark ${arabic}`}>
            {!is_final_assessment && (
              <p className="fs-17 text-secondary fw-700">
                <Localization text="module" /> {module_position}
              </p>
            )}
            <h4 className="fs-20 text-secondary-light fw-900">{title}</h4>
          </Title>

          <div>
            <div className="content">
              <div>
                {content && (
                  <HtmlParser className={arabic}>{content}</HtmlParser>
                )}

                {data.question_index && (
                  <p className={`fs-20 text-primary fw-900 mb-3 ${arabic}`}>
                    <Localization text="question" /> {data.question_index}
                  </p>
                )}

                {questions && (
                  <Questions
                    questions={questions}
                    setIsGotoNext={setIsGotoNext}
                    prevAnswersHandler={answers => {
                      prevAnswersHandler(answers, data.question_index);
                    }}
                    className={arabic}
                    prevAnswer={prevAnswers[data.question_index - 1]}
                    isCorrectAnswer={(submittedAnswers, currectAnswers) =>
                      isCorrectAnswer(
                        submittedAnswers,
                        currectAnswers,
                        data.question_index
                      )
                    }
                  />
                )}
              </div>
            </div>

            <div className="footer">
              <div className="d-flex justify-content-between">
                <button
                  onClick={onPrevHandler}
                  type="button"
                  className="btn btn-dark radius-0 back-btn"
                >
                  <BackIcon color="#a5d8ff" />
                </button>

                {/* <button
                to="/"
                type="button"
                className="btn btn-secondary radius-0 check-btn"
              >
                Check
              </button> */}

                <button
                  onClick={nextHandler}
                  className={`btn btn-secondary radius-0 text-left next-btn fs-14 fw-900 text-uppercase`}
                  disabled={!isGotoNext}
                >
                  <Localization text="next" />
                  <NextIcon color="#495057" className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinalAssessment;
